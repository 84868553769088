import { Flex } from "../../components/Flex";
import { Loader } from "../../components/Loader";
import { Slider } from "../../components/Slider";
import {
  CheckStatusEnum,
  IndividualCheckStatus,
} from "../../context/MandateStatusContext";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { IndividualChecksMap } from "./helper";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { CheckResults } from "./CheckResults";
import { DateTime } from "luxon";
import { Box } from "../../components/Box";
import { GrayCard } from "../../components/Card";
import { H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Link } from "../../components/Link";
import { capitalise } from "../../utils/string";
import { ApprovalComment } from "./approvalComment";
import { ExpansionPanel } from "../../components/ExpansionPanel";

export const AdverseMediaCheck = (props) => {
  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.adverse_media_check.title}
              </CheckTitle>
              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  id={id}
                  title={IndividualChecksMap.adverse_media_check.title}
                ></ManualAcceptReject>
              )}
            </Flex>

            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}

            {instance && instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              ></ApprovalComment>
            )}

            {instance && (
              <Section>
                <Flex justifyContent="space-between" mb={2}>
                  <Box>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Image
                        src={
                          instance?.adverse_media_details?.status ===
                          IndividualCheckStatus.Passed
                            ? success
                            : inReview
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        mr={1}
                      />
                      <H3>Adverse Media Check</H3>
                    </div>
                  </Box>
                  <Box></Box>
                </Flex>

                <Flex alignItems="center" mb={2}>
                  <CheckHeading>Result: &nbsp;</CheckHeading>
                  <CheckParagraph>
                    {instance?.adverse_media_details?.status
                      ? capitalise(instance?.adverse_media_details?.status)
                      : ""}
                  </CheckParagraph>
                </Flex>

                {instance?.adverse_media_details?.status !==
                  IndividualCheckStatus.Passed &&
                  instance?.adverse_media_details?.details &&
                  instance?.adverse_media_details?.details.length > 0 && (
                    <>
                      <Box mb={2}>
                        <CheckHeading>Details:</CheckHeading>
                      </Box>
                      {instance?.adverse_media_details?.details.map(
                        (detail, index) => (
                          <GrayCard key={index} mx="0px">
                            <H3
                              mb={3}
                              py={2}
                              borderBottom="1px solid"
                              borderBottomColor="gray.30"
                            >
                              {detail?.status}
                            </H3>
                            <CheckHeading color="gray.60" fontWeight="500">
                              Reason
                            </CheckHeading>
                            <Paragraph
                              mt={1}
                              mb={2}
                              color="gray.80"
                              fontWeight="500"
                            >
                              {detail?.reason ? detail?.reason : "-"}
                            </Paragraph>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Origin
                                </CheckHeading>
                                <Paragraph
                                  mt={1}
                                  mb={2}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_origin_name
                                    ? detail?.source_origin_name
                                    : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Name
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_name
                                    ? detail?.source_name
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Title
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.title ? detail?.title : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Date
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.date
                                    ? DateTime.fromISO(detail?.date).toFormat(
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            {/* <Box>
                              <CheckHeading color="gray.60" fontWeight="500">
                                URL
                              </CheckHeading>
                              <Paragraph
                                mb={2}
                                mt={1}
                                color="gray.80"
                                fontWeight="500"
                              >
                                {detail?.url ? (
                                  <Link
                                    fontSize="1"
                                    href={detail?.url}
                                    target="_blank"
                                  >
                                    {detail?.url}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </Paragraph>
                            </Box> */}
                          </GrayCard>
                        )
                      )}
                    </>
                  )}

                {instance?.adverse_media_details?.status ===
                  IndividualCheckStatus.Passed && (
                  <>
                    <GrayCard mx="0">
                      <Paragraph fontWeight="600" m="0">
                        No adverse media was found against this individual
                      </Paragraph>
                    </GrayCard>
                  </>
                )}

                {instance?.adverse_media_excluded_result &&
                  instance?.adverse_media_excluded_result?.details.length >
                    0 && (
                    <ExpansionPanel title="Excluded results">
                      {instance?.adverse_media_excluded_result?.details.map(
                        (detail, index) => (
                          <GrayCard
                            key={index}
                            mx="0px"
                            backgroundColor={"pink.100"}
                          >
                            <H3
                              mb={3}
                              py={2}
                              borderBottom="1px solid"
                              borderBottomColor="gray.30"
                            >
                              {detail?.status}
                            </H3>
                            <CheckHeading color="gray.60" fontWeight="500">
                              Reason
                            </CheckHeading>
                            <Paragraph
                              mt={1}
                              mb={2}
                              color="gray.80"
                              fontWeight="500"
                            >
                              {detail?.reason ? detail?.reason : "-"}
                            </Paragraph>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Origin
                                </CheckHeading>
                                <Paragraph
                                  mt={1}
                                  mb={2}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_origin_name
                                    ? detail?.source_origin_name
                                    : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Name
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_name
                                    ? detail?.source_name
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Title
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.title ? detail?.title : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Date
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.date
                                    ? DateTime.fromISO(detail?.date).toFormat(
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            <Box>
                              <CheckHeading color="gray.60" fontWeight="500">
                                URL
                              </CheckHeading>
                              <Paragraph
                                mb={2}
                                mt={1}
                                color="gray.80"
                                fontWeight="500"
                              >
                                {detail?.url ? (
                                  <Link
                                    fontSize="1"
                                    href={detail?.url}
                                    target="_blank"
                                  >
                                    {detail?.url}
                                  </Link>
                                ) : (
                                  "-"
                                )}
                              </Paragraph>
                            </Box>
                            
                            {detail?.excluded_reason &&
                              detail?.excluded_reason.length > 0 && (
                                <Box
                                  borderTop={"1px solid"}
                                  borderTopColor={"gray.100"}
                                >
                                  <Paragraph fontWeight={"bold"} mb={3}>
                                    Excluded reason
                                  </Paragraph>

                                  <Paragraph mb={1} fontWeight={"500"}>
                                    {detail?.excluded_reason.join(", ")}
                                  </Paragraph>
                                </Box>
                              )}

                              
                          </GrayCard>
                        )
                      )}
                    </ExpansionPanel>
                  )}
              </Section>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
