import { useState } from "react";
import styled from "styled-components";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import Topbar from "../components/Topbar";
import { FaComment, FaPlus } from "react-icons/fa";
import { BiCopy } from "react-icons/bi";
import { ROUTE } from "../routes";
import { useMandate } from "../data/useMandate";
import { Loader } from "../components/Loader";
import { UserFilter } from "../components/UserFilter";
import { useUsers } from "../data/useUsers";
import { Breadcrumb } from "../components/Breadcrumbs";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { Comments } from "../components/Comments";
import { Modal } from "../components/Modal";
import { Card } from "../components/Card";
import { H2, H3 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import Select, { Option } from "../components/Select";
import { useConsultants } from "../data/useConsultants";
import { Box } from "../components/Box";
import { Image } from "../components/Image";
import review from "../assets/card-in-review-icon.svg";
import pending from "../assets/pending-icon.svg";
import success from "../assets/card-success-icon.svg";
import archivedIcon from "../assets/archived-icon-09093E.svg";
import { IconButton } from "../components/IconButton";
import { ParticipantCard } from "../components/ParticipantCard";
import { IndividualSummary } from "../types/Individual";
import { AddIndividualModal } from "../components/Modals/AddIndividualModal";
import { IconButtonDropdown } from "../components/IconButtonDropdown";
import { IconButtonDropdownOption } from "../components/IconButtonDropdown/IconButtonDropdownOption";
import { FaTrash, FaCheck, FaTimes } from "react-icons/fa";
import { BiArchive } from "react-icons/bi";
import { useUpdateMandate } from "../data/useUpdateMandate";
import { queryClient } from "../queryClient";
import { showError } from "../utils/error-handling";
import { useDeleteIndividual } from "../data/useDeleteIndividual";
import { notify } from "../utils/notify";
import {
  MandateStatus,
  useMandateStatuses,
} from "../context/MandateStatusContext";
import { DateTime } from "luxon";
import { useDeleteMandate } from "../data/useDeleteMandate";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
  font-size: ${fontSizes[2]};
`}
`;

const ParticipantGrid = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;

const Container = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]};
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-bottom: ${space[5]}
  `}
`;

export const StatusDiv = styled.div<{ status: string }>`
  ${({ theme: { colors, fontSizes, space }, status }) => `
    border-radius: 25px;
    margin-right: ${space[1]};
    width: max-content;
    display: flex;
    align-items: center;
    padding: ${space[1]} ${space[3]};
    text-transform: capitalize;
    font-family: "Inter",sans-serif;
    font-size : ${fontSizes[1]};
    background: 
    ${
      status === MandateStatus.Completed
        ? colors.green[50]
        : status === MandateStatus.InProgress
        ? colors.blue[50]
        : status === MandateStatus.InReview
        ? colors.orange.primary
        : status === MandateStatus.Rejected
        ? colors.red[50]
        : colors.gray[60]
    };
    color: 
    ${
      status === MandateStatus.Completed
        ? colors.green.primary
        : status === MandateStatus.InProgress
        ? colors.blue.primary
        : status === MandateStatus.InReview
        ? colors.orange[50]
        : status === MandateStatus.Rejected
        ? colors.red.primary
        : colors.gray[100]
    };
    
 `}
`;

const Banner = styled.div`
  ${({ theme: { colors, fontSizes, space } }) => `
      height: 32px;
      background-color: ${colors.red.primary};
      color: ${colors.blue[100]};
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Inter",sans-serif;
  `}
`;

export const ApplicationView = () => {
  const [doubleClickOnDelete, setDoubleClickOnDelete] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const { getMandateStatusId } = useMandateStatuses();

  const {
    data: mandateData,
    isLoading: isLoadingMandate,
    isFetching,
  } = useMandate(parseInt(params.id));

  const mandate = mandateData?.data;
  const mandateReference = isLoadingMandate ? "..." : mandate?.name;

  const { data: consultants, isLoading: isLoadingConsultants } =
    useConsultants();
  const { data: users, isLoading: isLoadingUsers } = useUsers();
  const { mutate: updateMandate } = useUpdateMandate();
  const { mutate: deleteMandate } = useDeleteMandate();
  const { mutate: deleteIndividual } = useDeleteIndividual();

  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [userValue, setUserValue] = useState(null);
  const [consultantValue, setConsultantValue] = useState(null);

  // navigate to applications when url is invalid (:id is string)
  if (isNaN(parseInt(params.id))) {
    return <Navigate to={ROUTE.NOT_FOUND} replace />;
  }

  const isLoading = isLoadingMandate;

  if (
    !isFetching &&
    Number.isInteger(mandateData?.data?.status?.id) &&
    mandateData?.data?.status?.id === getMandateStatusId(MandateStatus.New)
  ) {
    return <Navigate to={"individuals"} replace />;
  }

  const userOptions = users?.data?.results.map(
    ({ first_name, last_name, id, colour }) => ({
      value: id,
      label: `${first_name} ${last_name}`,
      firstName: first_name,
      lastName: last_name,
      colour,
    })
  );

  const consultantOptions = consultants?.data?.results?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const getConsultant = consultantOptions?.find((element) => {
    return element?.value === mandateData?.data?.consultant?.id;
  });

  const consultantInitialValue =
    getConsultant ?? ({ label: "", value: 0 } as Option);

  const getAssignedUser = userOptions?.find((element) => {
    return element?.value === mandateData?.data?.assigned_user?.id;
  });

  const userInitialValue = getAssignedUser ?? {
    label: "",
    value: "",
    firstName: "",
    lastName: "",
    colour: "",
  };

  const handleSelectUser = (data) => {
    if (data) {
      const payload: any = {
        assigned_user: data?.value,
      };

      const onComplete = () => {
        setUserValue([data]);
        queryClient.invalidateQueries(["mandate"]);
      };

      updateMandate(
        {
          id: mandateData?.data?.id,
          ...payload,
        },
        {
          onSuccess: () => {
            onComplete();
          },
          onError: (err: any) => {
            showError(
              err,
              "A problem occurred while updating the assigned user."
            );
          },
        }
      );
    }
  };

  const handleUpdateApplication = ({ payload, notificationMessage }) => {
    const onComplete = () => {
      queryClient.invalidateQueries(["mandate"]);
      notify(notificationMessage, {
        type: "success",
      });
      navigate(ROUTE.APPLICATIONS);
    };

    updateMandate(
      {
        id: mandateData?.data?.id,
        ...payload,
      },
      {
        onSuccess: () => {
          onComplete();
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the application.");
        },
      }
    );
  };

  const handleDeleteApplication = () => {
    setDoubleClickOnDelete(true);
    deleteMandate(
      {
        id: mandateData?.data?.id,
      },
      {
        onSuccess: () => {
          notify("Application deleted", {
            type: "success",
          });
          navigate(ROUTE.APPLICATIONS);
          /* to resolve #7537
           commenting this to avoid again calling deleted mandate */
          // queryClient.invalidateQueries(["mandate"]);
        },
        onError: (err: any) => {
          setDoubleClickOnDelete(false);
          showError(err, "A problem occurred while deleting the application.");
        },
      }
    );
  };

  const handleSelectConsultant = (data) => {
    const payload: any = {
      consultant: data.value,
    };

    const onComplete = () => {
      setConsultantValue([data]);
      queryClient.invalidateQueries(["mandate"]);
    };

    updateMandate(
      {
        id: mandateData?.data?.id,
        ...payload,
      },
      {
        onSuccess: () => {
          onComplete();
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the consultant.");
        },
      }
    );
  };

  const handleOnRemoveIndividualClick = (id: number) => {
    deleteIndividual(
      { id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["mandate"]);
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while deleting the individual.");
        },
      }
    );
  };

  const breadCrumbLength = mandateData?.data?.name.length;

  return (
    <div>
      {!isLoading && !isFetching && mandateData?.data?.archived && (
        <Banner>
          <Image
            src={archivedIcon}
            title="archived"
            alt="Application archived"
            height="20px"
            width="24px"
            mr={1}
          />
          Archived
          {mandateData?.data?.archived_on && (
            <span>
              &nbsp; | &nbsp;
              {DateTime.fromISO(mandateData?.data?.archived_on).toFormat(
                "dd/MM/yyyy"
              )}
            </span>
          )}
        </Banner>
      )}

      {!isLoading && !isFetching && (
        <Topbar
          header={
            <Flex justifyContent="space-between" width="100%">
              <>
                <Breadcrumb>
                  <Breadcrumb.Section linkTo={ROUTE.APPLICATIONS}>
                    Applications
                  </Breadcrumb.Section>
                  <Breadcrumb.Section>
                    {/*{mandateData?.data?.name}*/}
                    {breadCrumbLength > 42
                      ? mandateData?.data?.name.substring(0, 42) + "..."
                      : mandateData?.data?.name}
                  </Breadcrumb.Section>
                </Breadcrumb>
                <Flex justifyContent="space-between">
                  <StatusDiv status={mandateData?.data?.status?.status}>
                    {mandateData?.data?.status?.status}
                  </StatusDiv>
                  {mandateData?.data?.status?.status ===
                    MandateStatus.InProgress && (
                    <Button
                      Icon={FaPlus}
                      iconSize="16px"
                      onClick={() => setIsModalOpen(true)}
                      mr={1}
                    >
                      Add Participant
                    </Button>
                  )}
                  {mandateData?.data?.status?.status ===
                    MandateStatus.InReview && (
                    <>
                      <Button
                        Icon={FaTimes}
                        iconSize="16px"
                        onClick={() => setIsRejectModalOpen(true)}
                        mr={1}
                        variant="secondary"
                        iconColor="red.primary"
                        color="red.primary"
                        borderColor="red.primary"
                      >
                        Reject
                      </Button>
                      <Button
                        Icon={FaCheck}
                        iconSize="16px"
                        iconColor="green.primary"
                        onClick={() => setIsCompleteModalOpen(true)}
                        mr={1}
                        variant="secondary"
                        color="green.primary"
                        borderColor="green.primary"
                      >
                        Complete
                      </Button>
                    </>
                  )}
                  <Button
                    Icon={FaComment}
                    iconSize="16px"
                    onClick={() => setIsCommentsOpen(!isCommentsOpen)}
                    mr={1}
                    variant="secondary"
                  >
                    Comments
                  </Button>
                  <UserFilter
                    size="regular"
                    options={userOptions}
                    initialValue={[userInitialValue]}
                    value={userValue}
                    onChange={handleSelectUser}
                    isMulti={false}
                    mr={1}
                    isDisabled={
                      mandateData?.data?.status?.status ===
                        MandateStatus.Completed ||
                      mandateData?.data?.status?.status ===
                        MandateStatus.Rejected
                    }
                  />
                  <IconButtonDropdown placement="bottom-end">
                    {mandate?.archived ? null : (
                      <IconButtonDropdownOption
                        onClick={() => setIsArchiveModalOpen(true)}
                        IconType={BiArchive}
                      >
                        Archive
                      </IconButtonDropdownOption>
                    )}
                    <IconButtonDropdownOption
                      onClick={() => setIsDeleteModalOpen(true)}
                      IconType={FaTrash}
                      isDestructive
                    >
                      Delete
                    </IconButtonDropdownOption>
                  </IconButtonDropdown>
                </Flex>
              </>
            </Flex>
          }
        ></Topbar>
      )}

      {(isLoading || isFetching || isLoadingConsultants || isLoadingUsers) && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && !isFetching && (
        <>
          <Container>
            <Flex justifyContent="start" paddingBottom={2} gap="24">
              <Box width={"25%"}>
                <H2 paddingBottom={2}>Details</H2>

                {/* Consultant */}
                <Card mb={1}>
                  {/* Consultant */}
                  <Heading>Consultant</Heading>

                  <Flex alignItems="center">
                    <Select
                      onChange={handleSelectConsultant}
                      value={consultantValue}
                      options={consultantOptions}
                      placeholder="Select consultant"
                      initialValue={consultantInitialValue}
                      width={"100%"}
                      isDisabled={
                        mandateData?.data?.status?.status ===
                          MandateStatus.Completed ||
                        mandateData?.data?.status?.status ===
                          MandateStatus.Rejected
                      }
                    />
                  </Flex>

                  {/* Type */}
                  <Heading mt={3}>Type</Heading>
                  <Paragraph mt={1} color="gray.50">
                    {mandateData?.data?.mandate_type?.name ?? "-"}
                  </Paragraph>

                  {/* Application created */}
                  <Heading mt={3}>Application created</Heading>
                  <Paragraph mt={1} color="gray.50">
                    {DateTime.fromISO(mandateData?.data?.created_on).toFormat(
                      "dd/MM/yyyy"
                    ) ?? "-"}
                  </Paragraph>

                  {/* ID */}
                  <Heading mt={3}>ID</Heading>
                  <Flex mt={1}>
                    <Paragraph mt={0} color="gray.50" mr={2}>
                      {mandateData?.data?.reference}
                    </Paragraph>
                    <IconButton
                      pt={0}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          mandateData?.data?.reference
                        );
                        notify("Copied to clipboard", {
                          type: "success",
                          position: "bottom-left",
                        });
                      }}
                      Type={BiCopy}
                      size="23px"
                    />
                  </Flex>

                  {/* Delete date */}
                  <Heading mt={2}>Delete Date</Heading>
                  <Paragraph mt={1} color="gray.50" mr={2}>
                    {DateTime.fromISO(
                      mandateData?.data?.will_delete_on
                    ).toFormat("dd/MM/yyyy") ?? "-"}
                  </Paragraph>
                  <Paragraph color="gray.40" mr={2}>
                    This application will be deleted automatically on this date
                    as part of our data retention policy.
                  </Paragraph>
                </Card>
              </Box>

              <Box width={"75%"}>
                <Box>
                  <H2 paddingBottom={2}>Checks</H2>
                  <Flex gap="24">
                    <Box flex={1}>
                      <Card>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Image
                            src={success}
                            alt="indicator icon"
                            height="20px"
                            width="24px"
                          />
                          <H3 flex={0.9}>Completed</H3>
                          <H2>{mandateData?.data?.checks_completed}</H2>
                        </Flex>
                      </Card>
                    </Box>
                    <Box flex={1}>
                      <Card>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Image
                            src={review}
                            alt="indicator icon"
                            height="20px"
                            width="24px"
                          />
                          <H3 flex={0.9}>In Review</H3>
                          <H2>{mandateData?.data?.checks_in_review}</H2>
                        </Flex>
                      </Card>
                    </Box>
                    <Box flex={1}>
                      <Card>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Image
                            src={pending}
                            alt="indicator icon"
                            height="20px"
                            width="24px"
                          />
                          <H3 flex={0.9}>Pending</H3>
                          <H2>{mandateData?.data?.checks_in_pending}</H2>
                        </Flex>
                      </Card>
                    </Box>
                  </Flex>
                </Box>

                <Box paddingBottom={2} paddingTop={2}>
                  <H2 paddingBottom={2}>
                    Participants (
                    {`${mandateData?.data?.individuals?.length}` ?? "?"})
                  </H2>
                  <ParticipantGrid>
                    {mandateData?.data?.individuals.map(
                      ({
                        first_name,
                        middle_name,
                        last_name,
                        date_of_birth,
                        email,
                        id,
                        invite_sent,
                        completed_actions,
                        checks_in_review,
                        checks_passed,
                        checks_completed,
                        total_actions,
                        total_checks,
                      }: IndividualSummary) => (
                        <ParticipantCard
                          key={id}
                          id={id}
                          firstName={first_name}
                          lastName={last_name}
                          middleName={middle_name}
                          dateOfBirth={
                            date_of_birth
                              ? DateTime.fromISO(date_of_birth).toFormat(
                                  "dd/MM/yyyy"
                                )
                              : ""
                          }
                          email={email}
                          onPdfDownloadClick={() => null}
                          onDeleteIndividualClick={() =>
                            handleOnRemoveIndividualClick(id)
                          }
                          inviteSent={invite_sent}
                          completedActions={completed_actions}
                          checksInReview={checks_in_review}
                          checksPassed={checks_passed}
                          checksCompleted={checks_completed}
                          totalActions={total_actions}
                          totalChecks={total_checks}
                        />
                      )
                    )}
                  </ParticipantGrid>
                </Box>
              </Box>
            </Flex>
          </Container>
          <Comments
            isOpen={isCommentsOpen}
            onRequestClose={() => setIsCommentsOpen(false)}
            referenceId={mandateData?.data?.id}
            commentType="Mandate"
          />
          <AddIndividualModal
            formTitle="Add new individual"
            formSubtitle="Please add the individual you would like to complete verification"
            submitButtonText="Save & Send Invite"
            onRequestClose={() => setIsModalOpen(false)}
            mandateId={mandateData?.data?.id}
            isOpen={isModalOpen}
          />
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
            title="Do you want to delete this?"
            content="This is an irreversible action, all mandate and participant information will be permanently deleted. "
            onModalConfirmClick={handleDeleteApplication}
            secondaryButtonText="Cancel"
            primaryButtonText="Delete"
            primaryButtonVariant="danger"
            hasSecondaryButton
            width="450px"
            doubleClick={doubleClickOnDelete}
          />
          <Modal
            isOpen={isArchiveModalOpen}
            onClose={() => setIsArchiveModalOpen(false)}
            title="Do you want to archive this?"
            content="This is an irreversible action, you will not be able to undo the process."
            onModalConfirmClick={() =>
              handleUpdateApplication({
                payload: { archived: true },
                notificationMessage: "Application archived",
              })
            }
            secondaryButtonText="Cancel"
            primaryButtonText="Archive"
            primaryButtonVariant="primary"
            hasSecondaryButton
            width="450px"
          />
          <Modal
            isOpen={isCompleteModalOpen}
            onClose={() => setIsCompleteModalOpen(false)}
            title="Do you want to complete this application?"
            content="This is an irreversible action, you will not be able to undo the process."
            onModalConfirmClick={() =>
              handleUpdateApplication({
                payload: {
                  status: getMandateStatusId(MandateStatus.Completed),
                },
                notificationMessage: "Application updated",
              })
            }
            secondaryButtonText="Cancel"
            primaryButtonText="Complete"
            primaryButtonVariant="primary"
            hasSecondaryButton
            width="450px"
          />
          <Modal
            isOpen={isRejectModalOpen}
            onClose={() => setIsRejectModalOpen(false)}
            title="Do you want to reject this application?"
            content="This is an irreversible action, if you reject it you will not be able to undo the process. "
            onModalConfirmClick={() =>
              handleUpdateApplication({
                payload: {
                  status: getMandateStatusId(MandateStatus.Rejected),
                },
                notificationMessage: "Application updated",
              })
            }
            secondaryButtonText="Cancel"
            primaryButtonText="Reject"
            primaryButtonVariant="danger"
            hasSecondaryButton
            width="450px"
          />
        </>
      )}
    </div>
  );
};
