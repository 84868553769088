import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { Flex } from "../../components/Flex";
import { Box } from "../../components/Box";
import { H2 } from "../../components/Heading";
import { Button } from "../../components/Button";
import { FaPlus } from "react-icons/fa";
import { ApplicationTypeCard } from "../../components/ApplicationTypeCard";
import { useMandateTypes } from "../../data/useMandateTypes";
import { MandateType } from "../../types/MandateType";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  ${({ theme: { colors, radii, space } }) => `
  grid-gap: ${space[3]};
  overflow: hidden;
  margin-top: 32px;
  `}
`;

const SettingsApplicationTypes = () => {
  // const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();
  const { data: mandateTypeQuery, isLoading } = useMandateTypes();
  const mandateTypes: MandateType[] = mandateTypeQuery?.data || [];

  return (
    <Box bg="white" borderRadius="16px" p={5} overflowY="hidden">
      <Flex justifyContent="space-between" alignItems="center">
        <H2 fontSize="3">Application Types</H2>
        <Button
          Icon={FaPlus}
          iconSize="16px"
          onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPE_NEW)}
        >
          New Application Type
        </Button>
      </Flex>
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <Grid>
          {mandateTypes?.map((applicationType) => (
            <ApplicationTypeCard
              key={applicationType.id}
              id={applicationType.id}
              name={applicationType.name}
              checkpoint={applicationType?.checkpoint}
              created_on={applicationType?.created_on}
              is_disabled={applicationType?.is_disabled}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default SettingsApplicationTypes;
