import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

// TODO Update when Create Invidiual takes correct fields
interface Params {
  mandate: number;
  title: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string;
  gender: Gender;
  email: string;
}

type Gender = "female" | "male" | "prefer_not_to_say" | "other";

export const useCreateIndividual = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}individual/individual/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
