import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Loader } from "../../components/Loader";
import { Paragraph } from "../../components/Paragraph";
import { Slider } from "../../components/Slider";
import { CheckStatusEnum } from "../../context/MandateStatusContext";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import downloadIcon from "../../assets/download-arrow.svg";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { Image } from "../../components/Image";
import { useGetImages } from "../../data/useGetImages";
import { Button } from "../../components/Button";
import { useState } from "react";
import { clearAll, notify } from "../../utils/notify";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { IndividualChecksMap } from "./helper";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { ApprovalComment } from "./approvalComment";
import { H3 } from "../../components/Heading";
import { DateTime } from "luxon";

const getFilenameFromUrl = (url) => {
  const urlObj = new URL(url);
  const segments = urlObj.pathname.split("/");
  return segments.pop();
};

const downloadFile = async (
  url,
  authHeader,
  setIsRequesting,
  notifyMessage
) => {
  setIsRequesting(true);
  notify(notifyMessage, {
    position: "bottom-left",
    type: "info",
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    autoClose: false,
  });

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: authHeader,
      },
      responseType: "arraybuffer",
    });

    clearAll();
    const docName = getFilenameFromUrl(url);
    const blob = new Blob([response.data]);
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = docName;
    link.click();
  } catch (error) {
    clearAll();
    notify("There was a problem while downloading the file", {
      position: "bottom-left",
      type: "error",
    });
  } finally {
    setIsRequesting(false);
  }
};

export const ProofOfAddressCheck = ({ isOpen, onRequestClose, id }) => {
  const { authHeader } = useAuth();
  const [isRequesting, setIsRequesting] = useState(false);
  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);
  const instance = individualCheckData?.data?.instance;

  const imageFormats = ["png", "jpeg", "jpg"];
  const pdfFormat = ["pdf"];

  const { data: documentImage } = useGetImages([
    instance?.document_image || "",
  ]);

  return (
    <>
      {isLoadingIndividualCheck ? (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      ) : (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.proof_of_address_check.title}
              </CheckTitle>
              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  id={id}
                  title={IndividualChecksMap.proof_of_address_check.title}
                />
              )}
            </Flex>
            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
                statusText={
                  individualCheckData?.data?.status?.status === "accepted"
                    ? "Uploaded"
                    : ""
                }
              />
            )}
            {instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              />
            )}
            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            />
            {instance && (
              <Section>
                {imageFormats.includes(
                  instance?.document_format.toLowerCase()
                ) ? (
                  <>
                    <Flex justifyContent="space-between" alignItems="center">
                      <H3>{instance?.document_type}</H3>
                      <Button
                        onClick={() =>
                          downloadFile(
                            instance?.document_image,
                            authHeader,
                            setIsRequesting,
                            "Downloading image, this could take up to 30 seconds"
                          )
                        }
                        variant="secondary"
                        color={"#2D374B"}
                      >
                        <Image
                          src={downloadIcon}
                          alt="download"
                          height="auto"
                          width="22px"
                          mr={1}
                        />
                        <Paragraph fontWeight="500" fontSize={1}>
                          {isRequesting ? "Downloading..." : "Download"}
                        </Paragraph>
                      </Button>
                    </Flex>
                    <Box>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>
                          Document Name
                        </CheckHeading>
                        <CheckParagraph>
                          {instance?.document_name}
                        </CheckParagraph>
                      </Flex>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>
                          Document Format
                        </CheckHeading>
                        <CheckParagraph>
                          {instance?.document_format}
                        </CheckParagraph>
                      </Flex>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>Issue date</CheckHeading>
                        <CheckParagraph>
                          {DateTime.fromISO(instance?.issue_date).toFormat(
                            "dd/MM/yyyy"
                          )}
                        </CheckParagraph>
                      </Flex>
                    </Box>
                    <Box>
                      {documentImage && (
                        <Image
                          width="100%"
                          borderRadius="20px"
                          src={`data:image/jpg;base64,${documentImage[0]}`}
                          alt="document image"
                        />
                      )}
                    </Box>
                  </>
                ) : pdfFormat.includes(
                    instance?.document_format.toLowerCase()
                  ) ? (
                  <>
                    <Flex justifyContent="space-between" alignItems="center">
                      <H3>{instance?.document_type}</H3>
                      <Button
                        onClick={() =>
                          downloadFile(
                            instance?.document,
                            authHeader,
                            setIsRequesting,
                            "Downloading pdf, this could take up to 30 seconds"
                          )
                        }
                        variant="secondary"
                        color={"#2D374B"}
                      >
                        <Image
                          src={downloadIcon}
                          alt="download"
                          height="auto"
                          width="22px"
                          mr={1}
                        />
                        <Paragraph fontWeight="500" fontSize={1}>
                          {isRequesting ? "Downloading..." : "Download"}
                        </Paragraph>
                      </Button>
                    </Flex>
                    <Box>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>
                          Document Name
                        </CheckHeading>
                        <CheckParagraph>
                          {instance?.document_name}
                        </CheckParagraph>
                      </Flex>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>
                          Document Format
                        </CheckHeading>
                        <CheckParagraph>
                          {instance?.document_format}
                        </CheckParagraph>
                      </Flex>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>Issue date</CheckHeading>
                        <CheckParagraph>
                          {DateTime.fromISO(instance?.issue_date).toFormat(
                            "dd/MM/yyyy"
                          )}
                        </CheckParagraph>
                      </Flex>
                      <Flex alignItems="center" my={2}>
                        <CheckHeading minWidth={200}>Note</CheckHeading>
                        <CheckParagraph>{instance?.note_ui ? instance?.note_ui : "-"}</CheckParagraph>
                      </Flex>
                    </Box>
                  </>
                ) : null}
              </Section>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
