import { Flex } from "../../components/Flex";
import { Slider } from "../../components/Slider";
import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Box } from "../../components/Box";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { TableBody } from "../../components/Table/TableBody";
import { TableCell } from "../../components/Table/TableCell";
import { TableHead } from "../../components/Table/TableHead";
import { TableRow } from "../../components/Table/TableRow";
import {
  IndividualCheckStatus,
  CheckStatusEnum,
} from "../../context/MandateStatusContext";
import {
  CheckContainer,
  CheckParagraph,
  CheckHeading,
  CheckTable,
  CheckTableHeaderRow,
  CheckTableCell,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { IndividualChecksMap } from "./helper";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { GrayCard } from "../../components/Card";
import { Paragraph } from "../../components/Paragraph";
import { DateTime } from "luxon";
import { ApprovalComment } from "./approvalComment";

export const BankDetailsCheck = (props) => {
  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.bank_account_details_check.title}
              </CheckTitle>

              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  id={id}
                  title={IndividualChecksMap.bank_account_details_check.title}
                ></ManualAcceptReject>
              )}
            </Flex>
            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}

            {instance && instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              ></ApprovalComment>
            )}

            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            ></CheckFields>

            {instance && instance?.bank_check && (
              <Section>
                <Flex justifyContent="space-between" mb={2}>
                  <Box>
                    <H3>
                      Bank Account
                      {instance?.bank_check?.status !==
                        IndividualCheckStatus.Passed && (
                        <>
                          &nbsp;-&nbsp;
                          {instance?.bank_check?.status}
                        </>
                      )}
                    </H3>
                  </Box>
                  <Box>
                    <Image
                      src={
                        instance?.bank_check?.status ===
                        IndividualCheckStatus.Passed
                          ? success
                          : inReview
                      }
                      alt="status icon"
                      height="20px"
                      width="auto"
                      mr={1}
                    />
                  </Box>
                </Flex>

                {instance?.bank_check?.status !==
                  IndividualCheckStatus.Passed && (
                  <>
                    <Flex mb={2} alignItems="center">
                      <CheckHeading mr={1}>Reason:</CheckHeading>
                      <CheckParagraph>
                        {instance?.bank_check?.reason
                          ? instance?.bank_check?.reason
                          : "-"}
                      </CheckParagraph>
                    </Flex>

                    <Box mb={2}>
                      <CheckHeading>Details:</CheckHeading>
                      <CheckTable>
                        <TableHead></TableHead>
                        <TableBody>
                          <CheckTableHeaderRow>
                            <CheckTableCell>Result</CheckTableCell>
                            <CheckTableCell>Code</CheckTableCell>
                            <CheckTableCell>Reason</CheckTableCell>
                          </CheckTableHeaderRow>
                          {instance?.bank_check?.details?.map(
                            ({ status, code, reason }, i) => (
                              <TableRow key={i}>
                                <TableCell
                                  width="15%"
                                  style={{ textTransform: "uppercase" }}
                                  fontWeight="500"
                                  color="gray.80"
                                  borderBottom="none"
                                >
                                  <Flex gap="8">
                                    <Image
                                      src={
                                        status === IndividualCheckStatus.Passed
                                          ? success
                                          : inReview
                                      }
                                      alt="status icon"
                                      height="20px"
                                      width="auto"
                                      mr={1}
                                    />
                                    {status}
                                  </Flex>
                                </TableCell>
                                <TableCell
                                  width="15%"
                                  color="gray.60"
                                  borderBottom="none"
                                >
                                  {code}
                                </TableCell>
                                <TableCell color="gray.60" borderBottom="none">
                                  {reason}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </CheckTable>
                    </Box>
                  </>
                )}

                {instance?.bank_check?.status ===
                  IndividualCheckStatus.Passed && (
                  <>
                    <Flex alignItems="center" mb={2}>
                      <CheckHeading>Result: &nbsp;</CheckHeading>
                      <CheckParagraph>PASSED</CheckParagraph>
                    </Flex>
                    <GrayCard mx="0">
                      <Paragraph fontWeight="600" m="0">
                        Supplied details did not match
                      </Paragraph>
                    </GrayCard>
                  </>
                )}
              </Section>
            )}

            {instance && instance?.sanctions_check && (
              <Section>
                <Flex justifyContent="space-between" mb={2}>
                  <Box>
                    <H3>
                      Sanctions Check
                      {instance?.sanctions_check?.status !==
                        IndividualCheckStatus.Passed && (
                        <>
                          &nbsp;-&nbsp;
                          {instance?.sanctions_check?.status}
                        </>
                      )}
                    </H3>
                  </Box>
                  <Box>
                    <Image
                      src={
                        instance?.sanctions_check?.status ===
                        IndividualCheckStatus.Passed
                          ? success
                          : inReview
                      }
                      alt="status icon"
                      height="20px"
                      width="auto"
                      mr={1}
                    />
                  </Box>
                </Flex>

                {instance?.sanctions_check?.status !==
                  IndividualCheckStatus.Passed &&
                  instance?.sanctions_check?.details &&
                  instance?.sanctions_check?.details.length > 0 && (
                    <>
                      <Box mb={2}>
                        <CheckHeading>Details:</CheckHeading>
                      </Box>
                      {instance?.sanctions_check?.details.map(
                        (detail, index) => (
                          <GrayCard key={index} mx="0px">
                            <H3
                              mb={3}
                              py={2}
                              borderBottom="1px solid"
                              borderBottomColor="gray.30"
                            >
                              {detail?.status}
                            </H3>
                            <CheckHeading color="gray.60" fontWeight="500">
                              Reason
                            </CheckHeading>
                            <Paragraph
                              mt={1}
                              mb={2}
                              color="gray.80"
                              fontWeight="500"
                            >
                              {detail?.reason ? detail?.reason : "-"}
                            </Paragraph>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Origin
                                </CheckHeading>
                                <Paragraph
                                  mt={1}
                                  mb={2}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_origin_name
                                    ? detail?.source_origin_name
                                    : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Source Name
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.source_name
                                    ? detail?.source_name
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            <Flex justifyContent="space-between">
                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Title
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.title ? detail?.title : "-"}
                                </Paragraph>
                              </Box>

                              <Box flex={1}>
                                <CheckHeading color="gray.60" fontWeight="500">
                                  Date
                                </CheckHeading>
                                <Paragraph
                                  mb={2}
                                  mt={1}
                                  color="gray.80"
                                  fontWeight="500"
                                >
                                  {detail?.date
                                    ? DateTime.fromISO(detail?.date).toFormat(
                                        "dd/MM/yyyy"
                                      )
                                    : "-"}
                                </Paragraph>
                              </Box>
                            </Flex>

                            <Box>
                              <CheckHeading color="gray.60" fontWeight="500">
                                URL
                              </CheckHeading>
                              <Paragraph
                                mb={2}
                                mt={1}
                                color="gray.80"
                                fontWeight="500"
                              >
                                {detail?.url ? detail?.url : "-"}
                              </Paragraph>
                            </Box>
                          </GrayCard>
                        )
                      )}
                    </>
                  )}

                {instance?.sanctions_check?.status ===
                  IndividualCheckStatus.Passed && (
                  <>
                    <Flex alignItems="center" mb={2}>
                      <CheckHeading>Result: &nbsp;</CheckHeading>
                      <CheckParagraph>PASSED</CheckParagraph>
                    </Flex>
                    <GrayCard mx="0">
                      <Paragraph fontWeight="600" m="0">
                        Supplied details did not match
                      </Paragraph>
                    </GrayCard>
                  </>
                )}
              </Section>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
