import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { addIndividualValidation } from "../../utils/validationSchema";
import { useCreateIndividual } from "../../data/useCreateIndividual";
import { displayError, showError } from "../../utils/error-handling";
import { NewApplicationFrame } from "./NewApplicationFrame";
import { MIN_AGE_APPLICATION } from "../../utils/constants";
import { useCommonTitles } from "../../data/useCommonTitles";
import { useState } from "react";
import { Loader } from "../../components/Loader";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

const inputProps = {
  minWidth: 200,
  flex: 1,
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

const Header = () => {
  return (
    <>
      <H2>Add Individuals</H2>
      <Paragraph>
        Please add the individuals you would like to complete verifications.
      </Paragraph>
    </>
  );
};

export const AddApplicationIndividual = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const mandateId = parseInt(params.id);

  const { mutate: createIndividual, isLoading } = useCreateIndividual();

  const { data } = useCommonTitles();

  const titleOptions = data?.data.map(({ name }) => ({
    label: name,
    value: name,
  })) as Option[];

  const handleCreateIndividual = (form: any) => {
    setDoubleClick(true);
    const dob = form.dateOfBirth
      ? DateTime.fromJSDate(form.dateOfBirth as Date).toFormat("yyyy-MM-dd")
      : null;

    if (mandateId) {
      createIndividual(
        {
          gender: form.gender,
          title: form.title,
          first_name: form.firstName,
          date_of_birth: dob,
          last_name: form.lastName,
          middle_name: form.middleName,
          mandate: mandateId,
          email: form.email,
        },
        {
          onSuccess: ({ data }) => {
            navigate(`/applications/${mandateId}/individuals`);
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(err, "A problem occurred while creating the individual.");
            // displayError(
            //   collapseErrors(extractErrors(err)) ||
            //     "A problem occurred while creating the individual."
            // );
            // logError(err);
          },
        }
      );
    } else {
      displayError(
        "A problem occurred while creating individual. No application selected"
      );
    }
  };

  return (
    <NewApplicationFrame step="individuals" mandateId={mandateId}>
      {/* {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )} */}

      {/* {!isLoading && ( */}
        <Formik
          initialValues={{
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: "",
            email: "",
            gender: "",
          }}
          validationSchema={addIndividualValidation}
          onSubmit={handleCreateIndividual}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
              <Header />
              <Flex {...rowProps}>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("title", option.value)
                    }
                    value={titleOptions?.find(
                      ({ value }) => value === props.values.title
                    )}
                    options={titleOptions}
                    placeholder="Select title"
                    mt="2"
                    label="Title"
                    name="title"
                    isRequired
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("gender", option.value)
                    }
                    value={genderOptions?.find(
                      ({ value }) => value === props.values.gender
                    )}
                    options={genderOptions}
                    placeholder="Select gender"
                    mt="2"
                    label="Select gender"
                    name="gender"
                    isRequired
                  />
                </div>
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  placeholder="Enter first name"
                  mt="2"
                  label="First name"
                  name="firstName"
                  hasError={Boolean(props.errors.firstName)}
                  errorMessage={props.errors.firstName as string}
                  isRequired
                />
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.middleName}
                  placeholder="Enter middle(s) name"
                  mt="2"
                  label="Middle name(s)"
                  name="middleName"
                  hasError={Boolean(props.errors.middleName)}
                  errorMessage={props.errors.middleName as string}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  placeholder="Enter last name"
                  mt="2"
                  label="Last name"
                  name="lastName"
                  hasError={Boolean(props.errors.lastName)}
                  errorMessage={props.errors.lastName as string}
                  isRequired
                />
                <DatePicker
                  value={props.values.dateOfBirth as any}
                  onChange={(date: any) => {
                    props.setFieldValue("dateOfBirth", date);
                  }}
                  mt="2"
                  maxDate={DateTime.now()
                    .minus({ years: MIN_AGE_APPLICATION })
                    .toJSDate()}
                  label="Date of birth"
                  name="dateOfBirth"
                  hasError={Boolean(props.errors.dateOfBirth)}
                  errorMessage={props.errors.dateOfBirth as string}
                  {...inputProps}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.email}
                  placeholder="Enter email address"
                  mt="2"
                  label="Email address"
                  name="email"
                  hasError={Boolean(props.errors.email)}
                  errorMessage={props.errors.email as string}
                  isRequired
                />
              </Flex>
              <Flex justifyContent="space-between">
                <Button
                  size="large"
                  mt="2"
                  mb="0"
                  variant="secondary"
                  onClick={() =>
                    navigate(`/applications/${mandateId}/individuals`)
                  }
                >
                  Back
                </Button>
                <Button
                  size="large"
                  mt="2"
                  mb="0"
                  type="submit"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    isLoading ||
                    doubleClick
                  }
                >
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      {/* )} */}
    </NewApplicationFrame>
  );
};
