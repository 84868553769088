import { H2 } from "./../../components/Heading/index";
import { Paragraph } from "./../../components/Paragraph/index";
import styled from "styled-components";
import { H3 } from "../../components/Heading";
import { Box } from "../../components/Box";

export const CheckContainer = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[2]} 0;
    height: calc(100% - 121px);
    overflow-y: auto;
  `}
`;
export const CheckTitle = styled(H2)`
  ${({ theme: { fontSizes, space } }) => `
    margin-bottom: ${space[2]}; 
    padding-left:  ${space[4]}; 
    padding-right: ${space[4]}; 
`}
`;
export const CheckSubTitle = styled(H3)`
  ${({ theme: { fontSizes, space } }) => `
    margin-bottom: ${space[2]}; 
    padding-left:  ${space[4]}; 
    padding-right: ${space[4]}; 
`}
`;
export const CheckHeading = styled(H3)`
  ${({ theme: { fontSizes, space } }) => `
    font-size: ${fontSizes[2]};
    margin-bottom: ${space[0]};
`}
`;
export const CheckParagraph = styled(Paragraph)`
  ${({ theme: { fontSizes, colors } }) => `
    font-size: ${fontSizes[2]};
    color: ${colors.gray[60]};
    margin-top: 0;
    margin-bottom: 0;
`}
`;
export const Section = styled(Box)`
  ${({ theme: { fontSizes, colors, space } }) => `
    border-bottom: 1px solid ${colors.gray[100]};
    margin-bottom: ${space[3]};
    padding-left:  ${space[4]}; 
    padding-right: ${space[4]};    

    &:last-child{
      margin-bottom: 0px;
      border-bottom: none;
    }
`}
`;
export const CheckTable = styled.table`
  ${({ theme: { space, fontFamily } }) => `
    border-spacing: 0;
    margin-top: ${space[1]};
    width: 100%;
    font-family: ${fontFamily};
`}
`;
export const CheckTableHeaderRow = styled.tr`
  ${({ theme: { colors } }) => `    
   background-color: ${colors.gray[5]};
   color: ${colors.gray[80]};      
 `}
`;

export const CheckTableCell = styled.td`
  ${({ theme: { colors, space } }) => ` 
  padding: ${space[1]};
  color: ${colors.gray[80]};
  font-weight: 500;      
 `}
`;
