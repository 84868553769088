import { Image } from "../Image";
import success from "../../assets/card-success-icon.svg";
import actions from "../../assets/card-indeterminate-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import { Flex } from "../Flex";
import { Paragraph } from "../Paragraph";
import { H3 } from "../Heading";
import styled from "styled-components";
// import { IconButtonDropdown } from "../IconButtonDropdown"
// import { IconButtonDropdownOption } from "../IconButtonDropdown/IconButtonDropdownOption";
// import { HiOutlineFolderDownload } from "react-icons/hi"
// import { FaTrash } from "react-icons/fa"
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

const units: Intl.RelativeTimeFormatUnit[] = [
  "year",
  "month",
  "week",
  "day",
  "hour",
  "minute",
  "second",
];

export const timeAgo = (dateTime: DateTime) => {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const StyledCard = styled(Link)`
  background-color: white;
  height: 168px;
  border-radius: 15px;
  padding: 24px;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease 0s;

  &:hover {
    transform: scale(1.025, 1.025);
    box-shadow: 0px 5px 9px rgb(0 0 0 / 12%);
  }

  &:active {
    transform: scale(1, 1);
  }
`;

export const ParticipantCard = ({
  id,
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  email,
  inviteSent,
  onDeleteIndividualClick,
  onPdfDownloadClick,
  completedActions,
  checksInReview,
  checksPassed,
  checksCompleted,
  totalActions,
}: any) => {
  const timeSince = inviteSent && timeAgo(DateTime.fromISO(inviteSent));

  // const handlePdfDownload = () => {
  //   onPdfDownloadClick();
  // };

  // const handleDeleteIndividual = () => {
  //   onDeleteIndividualClick();
  // };
  return (
    <>
      <StyledCard to={`individuals/${id}`}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          mb={3}
        >
          <Flex title="Actions">
            <Image
              src={actions}
              alt="actions icon"
              height="20px"
              width="24px"
            />
            <Paragraph color="gray.70" my="0px" ml={1}>
              {completedActions}/{totalActions}
            </Paragraph>
          </Flex>

          {checksInReview > 0 && (
            <Flex title="Checks In Review">
              <Image
                src={inReview}
                alt="checks in review icon"
                height="20px"
                width="24px"
              />
              <Paragraph color="gray.70" my="0px" ml={1}>
                {checksInReview}
              </Paragraph>
            </Flex>
          )}

          <Flex title="Checks Complete">
            <Image
              src={success}
              alt="checks complete icon"
              height="20px"
              width="24px"
            />
            <Paragraph color="gray.70" my="0px" ml={1}>
              {/* {checksPassed} */}
              {checksCompleted}
            </Paragraph>
          </Flex>
          {/* <IconButtonDropdown
            placement="bottom-end"
            position="absolute"
            right="0px"
            top="0px"
          >
            <IconButtonDropdownOption
              onClick={handlePdfDownload}
              IconType={HiOutlineFolderDownload}
            >
              Download a PDF report
            </IconButtonDropdownOption>
            <IconButtonDropdownOption
              onClick={handleDeleteIndividual}
              IconType={FaTrash}
              isDestructive
            >
              Delete
            </IconButtonDropdownOption>
          </IconButtonDropdown> */}
        </Flex>
        <H3 my="2">
          {firstName ? ` ${firstName}` : ""}
          {/*{middleName ? ` ${middleName}` : ""}*/}
          {lastName ? ` ${lastName}` : ""}
        </H3>
        {/* <Flex
          alignItems="center"
          justifyContent="space-between"
          // maxWidth="400px"
        > */}
        <Paragraph color="gray.50" my={0}>
          {email.length > 30 ? email.substring(0, 30) + "..." : email}
        </Paragraph>
        {/* <Paragraph color="gray.50">{" \u2022 "}</Paragraph> */}
        <Paragraph color="gray.50" my={0}>
          {dateOfBirth}
        </Paragraph>
        {/* </Flex> */}
        <Flex>
          {timeSince && (
            <Paragraph color="gray.40">{`Invite sent ${timeSince}`}</Paragraph>
          )}
        </Flex>
      </StyledCard>
    </>
  );
};
