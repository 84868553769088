import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import { useState } from "react";
import { Flex } from "../../components/Flex";
import { Image } from "../../components/Image";
import accept from "../../assets/manual-accept.svg";
import reject from "../../assets/manual-reject.svg";
import { useManualAcceptReject } from "../../data/useManualAcceptReject";
import { showError } from "../../utils/error-handling";
// import { useCreateComment } from "../../data/useCreateComment";
import { queryClient } from "../../queryClient";

interface Props {
  title: string;
  id: number;
}

export const ManualAcceptReject = (props: Props) => {
  const { title, id } = props;

  const [isManualAcceptRejectOpen, setIsManualAcceptRejectOpen] =
    useState(false);
  const [isManualAcceptRejectSuccess, setIsManualAcceptRejectSuccess] =
    useState(false);
  const [action, setAction] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const { mutate: manualAcceptReject } = useManualAcceptReject();

  // const { mutate: createComment } = useCreateComment();

  const handleManualAcceptOrReject = (comment: string) => {
    setIsLoadingAction(true);
    manualAcceptReject(
      { id: id, action: action, comment, comment_for: "IndividualCheck" },
      {
        onSuccess: () => {
          // addComment(comment);
          setIsLoadingAction(false);
          setIsManualAcceptRejectOpen(false);
          setIsManualAcceptRejectSuccess(true);
        },
        onError: (err: any) => {
          setIsLoadingAction(false);
          showError(
            err,
            `A problem occurred while manually ${
              action === "accept" ? "accepting" : "rejecting"
            }  check.`
          );
        },
      }
    );
  };

  // const addComment = (comment: string) => {
  //   createComment(
  //     {
  //       comment,
  //       comment_for: "IndividualCheck",
  //       reference: id,
  //     },
  //     {
  //       onError: (err: any) => {
  //         setIsLoadingAction(false);
  //         showError(err, "A problem occurred while sending your comment.");
  //       },
  //       onSuccess: () => {
  //         setIsLoadingAction(false);
  //         setIsManualAcceptRejectOpen(false);
  //         setIsManualAcceptRejectSuccess(true);
  //       },
  //     }
  //   );
  // };

  const handleSuccessClose = () => {
    setIsManualAcceptRejectSuccess(false);
    queryClient.invalidateQueries(["individualCheckDetails", id]);
  };

  return (
    <>
      <Flex mr={5}>
        <Button
          px={5}
          variant="secondary"
          color="red.primary"
          mr={2}
          fontWeight="500"
          onClick={() => {
            setAction("reject");
            setIsManualAcceptRejectOpen(true);
          }}
        >
          <Image src={reject} alt="reject" height="20px" width="auto" mr={1} />
          Reject
        </Button>

        <Button
          px={5}
          mr={4}
          variant="secondary"
          fontWeight="500"
          color="blue.primary"
          onClick={() => {
            setAction("accept");
            setIsManualAcceptRejectOpen(true);
          }}
        >
          <Image src={accept} alt="accept" height="20px" width="auto" mr={1} />
          Accept
        </Button>
      </Flex>

      <Modal
        isClosingDisabled={isLoadingAction}
        isOpen={isManualAcceptRejectOpen}
        onClose={() => setIsManualAcceptRejectOpen(false)}
        onModalConfirmClick={handleManualAcceptOrReject}
        title={`Are you sure you want to ${action} the ${title}?`}
        content={`Please leave a comment to confirm why you are ${
          action === "accept" ? "accepting" : "rejecting"
        } this check.`}
        hasSecondaryButton
        secondaryButtonText="Cancel"
        width="450px"
        primaryButtonText={action === "accept" ? "Accept" : "Reject"}
        primaryButtonVariant="primary"
        hasCommentBox={true}
      />

      <Modal
        isOpen={isManualAcceptRejectSuccess}
        onClose={handleSuccessClose}
        hasPrimaryButton={false}
        title={`${title} ${action === "accept" ? "Accepted" : "Rejected"}`}
        content={`Thank you for ${
          action === "accept" ? "accepting" : "rejecting"
        } this check. The status has been updated.`}
        hasSecondaryButton
        secondaryButtonText="Close"
        width="450px"
      />
    </>
  );
};
