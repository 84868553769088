import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import axios from "axios";

export const useCreateBillingInfo = () => {
  const { authHeader } = useAuth();

  return useMutation((params) => {
    return axios.post(
      `${env.verifyServiceUrl}user/billing_information/`,
      params,
      {
        headers: {
          Authorization: authHeader,
        },
      }
    );
  });
};
