import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

export const useUpdateSOFActive = () => {
  const { authHeader } = useAuth();

  return useMutation(
    ({ id, option }: { id: number; option: { active: boolean } }) => {
      return axios.patch(
        `${env.verifyServiceUrl}individual/sof_risk_level/${id}/`,
        option,
        {
          headers: {
            Authorization: authHeader,
          },
        }
      );
    }
  );
};
