import { Form, Formik } from "formik";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../routes";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { CheckCard } from "../../components/CheckCard";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { IconButton } from "../../components/IconButton";
import { Input } from "../../components/Input";
import { ApplicationTypePreviewModal } from "../../components/Modals/ApplicationTypePreviewModal";
import { Paragraph } from "../../components/Paragraph";
import { TextArea } from "../../components/TextArea";
import { useChecks } from "../../data/useChecks";
import { useCreateMandateType } from "../../data/useCreateMandateType";
import { notify } from "../../utils/notify";
import { settingsApplicationTypesValidation } from "../../utils/validationSchema";
import { useEffect } from "react";
import { Image } from "../../components/Image";
import { Toggle } from "../../components/Toggle";
import Email from "../../assets/Mail-Email-Letter.svg";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(334px, 1fr));
  ${({ theme: { space } }) => `
  grid-gap: ${space[3]};
  overflow: hidden;
  margin-top: 26px;
  `}
`;

const SettingsApplicationTypeNew = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: createMandateType } = useCreateMandateType();
  const [formData, setFormData] = useState(null);
  const [checkpoints, setCheckpoints] = useState([]);
  const [hasAddedCoreChecks, setHasAddedCoreChecks] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const navigate = useNavigate();
  const { data: checkListQuery } = useChecks();
  const checkList = checkListQuery?.data;
  const coreChecks = checkList
    ?.filter((check) => check.check_type === "CORE")
    .sort((a, b) => a.order - b.order);
  const addOnChecks = checkList
    ?.filter((check) => check.check_type === "ADDON")
    .sort((a, b) => a.order - b.order);

  useEffect(() => {
    if (!hasAddedCoreChecks && coreChecks && coreChecks.length > 0) {
      const core = coreChecks.map((check) => check.id);
      setCheckpoints((prevCheckpoints) => [...prevCheckpoints, ...core]);
      setHasAddedCoreChecks(true);
    }
  }, [coreChecks, hasAddedCoreChecks]);

  useEffect(() => {
    if (isConfirmed && !isModalOpen) {
      handleSubmit(formData);
    }
  }, [isConfirmed, isModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheckpoints = (id: number, isAdding: boolean) => {
    if (isAdding) {
      setCheckpoints([...checkpoints, id]);
    } else {
      setCheckpoints(checkpoints.filter((checkpoint) => checkpoint !== id));
    }
  };

  const handleConfirm = () => {
    setIsConfirmed(true);
    setIsModalOpen(false);
  };

  const handleSubmit = (data) => {
    const payload = {
      ...data,
      code: data.name,
      checkpoint: checkpoints,
    };
    if (!isConfirmed) {
      setFormData(payload);
      setIsModalOpen(true);
    } else {
      createMandateType(payload, {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            notify("Application Type created successfully!", {
              type: "success",
            });
          }
          navigate(ROUTE.SETTINGS_APPLICATION_TYPES);
        },
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          description: "",
          action_reminder_email: false,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validate={() => null}
        validationSchema={settingsApplicationTypesValidation}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Flex
              bg="white"
              width="100%"
              gap="12"
              justifyContent="space-between"
              flexWrap="wrap"
              borderRadius="16px"
              pb={4}
            >
              <Box pl={4} pr={2} pt={4} flex={4} minWidth={400}>
                <H2 fontSize="3">Create New Application Type</H2>

                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Flex>
                    <Image
                      src={Email}
                      alt="actions icon"
                      height="20px"
                      width="24px"
                    />
                    <Paragraph m={"0px"} fontWeight={600}>
                      Auto-email reminders
                    </Paragraph>
                  </Flex>

                  <Toggle
                    mt={1}
                    isChecked={props.values.action_reminder_email}
                    onChange={(e: any) => {
                      props.setFieldValue(
                        "action_reminder_email",
                        e.target.checked
                      );
                    }}
                  />
                </Flex>

                <Box>
                  <Box my={2}>
                    <Input
                      onChange={props.handleChange}
                      value={props.values.name}
                      placeholder="Enter an Application Type Name"
                      label="Application Type Name"
                      name="name"
                      isRequired
                      hasError={Boolean(props.errors.name)}
                      errorMessage={props.errors.name}
                    />
                  </Box>
                  <Box>
                    <TextArea
                      onChange={props.handleChange}
                      value={props.values.description}
                      placeholder="Enter a description"
                      label="Description"
                      name="description"
                      rows={4}
                      isRequired
                      hasError={Boolean(props.errors.description)}
                      errorMessage={props.errors.description}
                    />
                  </Box>
                </Box>
                <Box pt={2}>
                  <H2 fontSize={3} fontWeight={500}>
                    Core Checks
                  </H2>
                  {!coreChecks || coreChecks.length === 0 ? (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      minHeight="25vh"
                    >
                      <Loader />
                    </Flex>
                  ) : (
                    <Grid>
                      {coreChecks?.map((check) => (
                        <CheckCard
                          key={check.id}
                          id={check.id}
                          name={check.name}
                          actual_name={check.actual_name}
                          check_type={check.check_type}
                          handleCheckpoints={handleCheckpoints}
                        />
                      ))}
                    </Grid>
                  )}
                </Box>
              </Box>
              <Box
                borderRadius={0}
                pr={4}
                pt={4}
                flex={2}
                height="fit-content"
                position="relative"
              >
                <IconButton
                  Type={IoMdClose}
                  size="23px"
                  position="absolute"
                  top={12}
                  right={12}
                  onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPES)}
                />

                <Box>
                  <H2 fontSize={3} fontWeight={500} pt={1} mt={4}>
                    Check Add-ons
                  </H2>
                  <Paragraph fontSize={1} fontWeight="400" color="gray.60">
                    Please select any additional checks that are to apply to the
                    individuals of this application type. For more information
                    on the costs please visit our{" "}
                    <a
                      href="https://www.tiller-verify.com/pricing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      pricing page
                    </a>
                  </Paragraph>
                </Box>
                {!coreChecks || coreChecks.length === 0 ? (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    minHeight="25vh"
                  >
                    <Loader />
                  </Flex>
                ) : (
                  addOnChecks?.map((check) => (
                    <Box my={1} key={check.id}>
                      <CheckCard
                        id={check.id}
                        name={check.name}
                        actual_name={check.actual_name}
                        check_type={check.check_type}
                        handleCheckpoints={handleCheckpoints}
                      />
                    </Box>
                  ))
                )}
                <Flex mt={4} justifyContent="end">
                  <Button
                    mr="2"
                    size="regular"
                    variant="secondary"
                    onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPES)}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" size="regular">
                    Create
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
      <ApplicationTypePreviewModal
        onRequestClose={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
        data={formData}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default SettingsApplicationTypeNew;
