import styled from "styled-components";
import { space } from "styled-system";
import { Box, BoxProps } from "../Box";
import { Label } from "../Label";
import { Paragraph } from "../Paragraph";

const InputBase = styled.textarea`
  ${({ hasError, disabled, theme: { radii, colors, space, fontSizes } }: any) => `
        resize: none;
        width: 100%;
        box-sizing: border-box;
        border-radius: ${radii[0]};
        border: 2px solid ${hasError ? colors.red.primary : colors.gray[30]};
        font-family: "Inter", sans-serif;
        color: ${colors.blue[100]};
        font-size: ${fontSizes[1]};
        padding: ${space[2]};
        transition: all .1s ease;

        ::placeholder {
            color: ${colors.gray[50]};
        }
        ${
          disabled
            ? `
            cursor: not-allowed;
          `
            : ""
        }

          ::placeholder {
              color: ${colors.gray[50]};
          }

          :hover {
              ${
                hasError
                  ? ""
                  : `
                  border: 2px solid ${colors.gray[40]};
              `
              }
          }
      `}

  ${space}
`;

export interface TextAreaProps extends BoxProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "password" | "search" | "number";
  hasError?: boolean;
  errorMessage?: string;
  placeholder?: string;
  label?: string;
  name?: string;
  size?: "small" | "regular";
  color?: string;
  min?: number;
  max?: number;
  rows?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
}

export const TextArea = (props: TextAreaProps) => {
  const {
    onChange,
    type = "text",
    label,
    value,
    errorMessage,
    hasError,
    size = "regular",
    placeholder,
    name,
    pl,
    min,
    max,
    rows,
    isDisabled,
    isRequired,
    ...styleProps
  } = props;

  const inputProps = {
    errorMessage,
    hasError,
    buttonSize: size,
    type,
    placeholder,
    name,
    value,
    pl,
    min,
    max,
    rows,
    disabled: isDisabled,
  } as any;

  const handleChange = (event: any) => {
    onChange(event);
  };

  return (
    <Box position="relative" {...styleProps}>
      {label && (
        <Label>
          {label}
          {isRequired ? " *" : ""}
        </Label>
      )}

      <InputBase onChange={handleChange} {...inputProps} />

      {errorMessage && (
        <Paragraph color="red.primary" fontSize="1" mt="1" textAlign="right">
          {errorMessage}
        </Paragraph>
      )}
    </Box>
  );
};
