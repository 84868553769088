import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ROUTE } from "../../routes";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikProps, Form } from "formik";
import { Input } from "../../components/Input";
import { TextArea } from "../../components/TextArea";
import { Button } from "../../components/Button";
import { Loader } from "../../components/Loader";
import { Flex } from "../../components/Flex";
import { Box } from "../../components/Box";
import { IconButton } from "../../components/IconButton";
import { FaArrowLeft, FaUser, FaEdit, FaTrash } from "react-icons/fa";
import { H1, H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Image } from "../../components/Image";
import { Toggle } from "../../components/Toggle";
import { AlertBox } from "../../components/AlertBox";
import { ConfirmModal } from "../../components/Modals/ConfirmModal";
import { queryClient } from "../../queryClient";
import { useDeleteMandateType } from "../../data/useDeleteMandateType";
import { useUpdateMandateType } from "../../data/useUpdateMandateType";
import { useMandateType } from "../../data/useMandateType";
import { MandateType } from "../../types/MandateType";
import { useChecks } from "../../data/useChecks";
import * as icons from "../../assets/checkpoints";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { settingsApplicationTypesValidation } from "../../utils/validationSchema";
import Email from "../../assets/Mail-Email-Letter.svg";
import deleteIcon from "../../assets/settings/delete-red.svg";
import editIcon from "../../assets/settings/edit.svg";

const Wrapper = styled(Box)`
  ${({ theme: { colors, radii, space } }) => `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      color: inherit;
      background: ${colors.gray[10]};
      border-radius: ${radii[1]};
      padding: ${space[2]};
      position: relative;
      transition: all 0.2s ease 0s;
      border: 1px solid white;
  `}
`;

const EditTools = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: end;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const EditToolsItem = styled.li`
  display: flex;
  align-items: center;
  font-weight: 400 !important;
  ${({ theme: { fontFamily, fontSizes, colors, space } }) => `
  font-family: ${fontFamily};
  font-size: ${fontSizes[2]};
  color: ${colors.blue[60]};
  padding-left: ${space[2]};
    `}
`;

const CheckPointList = styled.ul`
  list-style-type: none;
  margin-top: 0;
  padding: 0;
  min-height: 100px;
`;

const CheckPointListItem = styled.li`
  display: flex;
  align-items: center;
  line-height: 3.3em;
  font-weight: 400 !important;
  ${({ theme: { fontFamily, fontSizes, colors } }) => `
  font-family: ${fontFamily};
  font-size: ${fontSizes[2]};
  color: ${colors.blue[60]};
  border-bottom: 2px solid ${colors.gray[10]};
  `}
`;

const SettingsApplicationTypeView = () => {
  const params = useParams();
  const navigate = useNavigate();

  const formRef = useRef<FormikProps<any>>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [selectedMandateTypeId, setSelectedMandateTypeId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [editMode, setEditMode] = useState(false);

  const { mutate: deleteMandateType } = useDeleteMandateType();
  const { mutate: updateMandateType } = useUpdateMandateType();
  const {
    data: mandateTypeQuery,
    refetch,
    isLoading,
  } = useMandateType(parseInt(params.id));
  const [mandateType, setMandateType] = useState<MandateType | null>(null);
  const { data: checkListQuery } = useChecks();
  const checkList = checkListQuery?.data;

  useEffect(() => {
    setMandateType(mandateTypeQuery?.data);
  }, [mandateTypeQuery?.data]);

  useEffect(() => {
    const id = parseInt(params.id);
    setSelectedMandateTypeId(id);
  }, [params.id]);

  useEffect(() => {
    refetch();
  }, [editMode, refetch]);

  const core = checkList
    ?.filter((check) => check.check_type === "CORE")
    .sort((a, b) => a.order - b.order);
  const addOn = checkList
    ?.filter((check) => check.check_type === "ADDON")
    .sort((a, b) => a.order - b.order);

  const coreCheckpoints =
    mandateType?.checkpoint && core
      ? mandateType.checkpoint.filter((item) =>
          core.some((check) => check.id === item.id)
        )
      : [];

  const addOnCheckpoints =
    mandateType?.checkpoint && addOn
      ? mandateType.checkpoint.filter((item) =>
          addOn.some((check) => check.id === item.id)
        )
      : [];

  const handleUpdateMandateTypeEnabled = () => {
    updateMandateType(
      {
        id: selectedMandateTypeId,
        is_disabled: !mandateType?.is_disabled as boolean,
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            ["mandateTypeQuery", selectedMandateTypeId],
            data
          );
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while updating the Application Type."
          );
        },
        onSettled: () => {
          refetch();
        },
      }
    );
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleDelete = () => {
    if (mandateType?.associated_individuals > 0) {
      setAlertMessage(
        `You can't delete this application type at this time because there are ${mandateType?.associated_individuals} individuals associated with it.`
      );
      setShowAlert(true);
      return;
    }
    setIsConfirmDeleteOpen(true);
  };

  const handleSubmit = (values: any) => {
    updateMandateType(
      {
        id: selectedMandateTypeId,
        name: values.name,
        description: values.description,
        action_reminder_email: values.action_reminder_email,
      },
      {
        onSuccess: (data) => {
          notify("Application Type has been saved", {
            type: "success",
          });
          queryClient.setQueryData(
            ["mandateTypeQuery", selectedMandateTypeId],
            data
          );
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while updating the Application Type."
          );
        },
        onSettled: () => {
          setEditMode(false);
        },
      }
    );
  };

  const saveMandateType = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const confirmDeleteMandateType = () => {
    setIsConfirmDeleteOpen(false);
    deleteMandateType(
      { id: selectedMandateTypeId, deleted: true },
      {
        onSuccess: () => {
          notify("Application Type has been deleted", {
            type: "success",
          });
          navigate(ROUTE.SETTINGS_APPLICATION_TYPES);
          queryClient.invalidateQueries(["manadateTypes"]);
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while updating the Applicaiton Type."
          );
        },
        onSettled: () => {
          setSelectedMandateTypeId(null);
        },
      }
    );
  };

  if (!mandateType) {
    return null;
  }

  return (
    <>
      <Flex
        bg="white"
        width="96%"
        gap="22"
        justifyContent="space-between"
        flexWrap="wrap"
        borderRadius="16px"
        padding={32}
      >
        <Flex width="100%" justifyContent="space-between">
          <Box>
            <Flex>
              {editMode ? (
                <IconButton
                  onClick={() => setEditMode(false)}
                  Type={FaArrowLeft}
                  right={2}
                  size="14px"
                  top={2}
                  color="gray.50"
                />
              ) : (
                <IconButton
                  onClick={() => navigate(ROUTE.SETTINGS_APPLICATION_TYPES)}
                  Type={FaArrowLeft}
                  right={2}
                  size="14px"
                  top={2}
                  color="gray.50"
                />
              )}
              {isLoading ? (
                <div>Loading...</div>
              ) : (
                <H1 fontSize={3} ml={2}>
                  {mandateType?.name}
                </H1>
              )}
            </Flex>
          </Box>
          <Box width="30%">
            <EditTools>
              {editMode ? (
                <EditToolsItem>
                  <Button variant="primary" onClick={saveMandateType}>
                    Save
                  </Button>
                </EditToolsItem>
              ) : (
                <>
                  <EditToolsItem>
                    <Flex flexDirection="row" alignItems="center">
                      <Toggle
                        mt={1}
                        isChecked={!mandateType?.is_disabled}
                        onChange={handleUpdateMandateTypeEnabled}
                      />
                      <Paragraph ml={1} color="gray.50">
                        Enabled
                      </Paragraph>
                    </Flex>
                  </EditToolsItem>
                  <EditToolsItem
                    onClick={handleEdit}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={editIcon}
                      alt="edit icon"
                      height="25px"
                      width="25px"
                      marginRight={"4px"}
                    />
                    <Paragraph color="gray.50">Edit</Paragraph>
                  </EditToolsItem>
                  <EditToolsItem
                    onClick={handleDelete}
                    style={{ cursor: "pointer" }}
                  >
                    <Image
                      src={deleteIcon}
                      alt="delete icon"
                      height="25px"
                      width="25px"
                      marginRight={"4px"}
                    />
                    <Paragraph color="red.primary">Delete</Paragraph>
                  </EditToolsItem>
                </>
              )}
            </EditTools>
          </Box>
        </Flex>
        {showAlert && (
          <AlertBox closeAlert={() => setShowAlert(false)} alertType="warning">
            {alertMessage}
          </AlertBox>
        )}
        <Flex width="100%" gap="22">
          <Flex flexDirection="column" width="50%" gap="22">
            <Formik
              initialValues={{
                name: mandateType?.name,
                description: mandateType?.description,
                action_reminder_email: mandateType?.action_reminder_email,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              validate={() => null}
              validationSchema={settingsApplicationTypesValidation}
              innerRef={formRef}
            >
              {(props) => (
                <Form>
                  <H3 fontSize={2}>Application Type Name</H3>
                  {editMode ? (
                    <Box mb={4}>
                      <Input
                        name="name"
                        value={props.values.name}
                        onChange={props.handleChange}
                        isRequired
                        hasError={Boolean(props.errors.name)}
                        errorMessage={props.errors.name as string}
                      />
                    </Box>
                  ) : (
                    <Wrapper>
                      <Paragraph fontSize={1} color="gray.50">
                        {isLoading ? <div>Loading...</div> : mandateType?.name}
                      </Paragraph>
                    </Wrapper>
                  )}

                  <H3 fontSize={2}>Description</H3>
                  {editMode ? (
                    <TextArea
                      name="description"
                      rows={4}
                      value={props.values.description}
                      onChange={props.handleChange}
                      isRequired
                      hasError={Boolean(props.errors.description)}
                      errorMessage={props.errors.description as string}
                    />
                  ) : (
                    <Wrapper>
                      <Paragraph fontSize={1} color="gray.50">
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : (
                          mandateType?.description
                        )}
                      </Paragraph>
                    </Wrapper>
                  )}

                  {editMode && (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Flex>
                        <Image
                          src={Email}
                          alt="actions icon"
                          height="20px"
                          width="24px"
                        />
                        <Paragraph m={"0px"} fontWeight={600}>
                          Auto-email reminders
                        </Paragraph>
                      </Flex>

                      <Toggle
                        mt={1}
                        isChecked={props.values.action_reminder_email}
                        onChange={(e: any) => {
                          props.setFieldValue(
                            "action_reminder_email",
                            e.target.checked
                          );
                        }}
                      />
                    </Flex>
                  )}
                </Form>
              )}
            </Formik>
            {!editMode && (
              <Wrapper>
                <Flex justifyContent="start">
                  <FaUser size={40} color="blue" />
                  <Paragraph ml={2} width="50%">
                    {mandateType?.associated_individuals}
                    {mandateType?.associated_individuals === 1
                      ? " Individual is"
                      : " Individuals are"}{" "}
                    associated with this application type
                  </Paragraph>
                </Flex>
              </Wrapper>
            )}

            {!editMode && (
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Flex>
                  <Image
                    src={Email}
                    alt="actions icon"
                    height="20px"
                    width="24px"
                  />
                  <Paragraph m={"0px"} fontWeight={600}>
                    Auto-email reminders
                  </Paragraph>
                </Flex>

                <Paragraph m={"0px"} fontWeight={600}>
                  {mandateType?.action_reminder_email ? "Active" : "Inactive"}
                </Paragraph>
              </Flex>
            )}
          </Flex>
          <Flex flexDirection="column" width="50%">
            {coreCheckpoints.length === 0 && addOnCheckpoints.length === 0 ? (
              <Flex
                justifyContent="center"
                alignItems="center"
                minHeight="25vh"
              >
                <Loader />
              </Flex>
            ) : (
              <>
                {editMode && (
                  <Box mb={4} mr={4} pr={2}>
                    <AlertBox alertType="info">
                      Checks can’t be changed once application type is created.
                    </AlertBox>
                  </Box>
                )}
                <Box>
                  <H2 fontSize={3}>Core Checks</H2>
                  <CheckPointList>
                    {coreCheckpoints.map((check) => (
                      <CheckPointListItem key={check.id}>
                        <Image
                          src={icons[check.name.replace("_&", "")]}
                          alt={`${check.actual_name} icon`}
                          width="32px"
                          mr={1}
                        />
                        {check.actual_name}
                      </CheckPointListItem>
                    ))}
                  </CheckPointList>
                </Box>
                {addOnCheckpoints.length > 0 && (
                  <Box>
                    <H2 fontSize={3}>Add-ons</H2>
                    <CheckPointList>
                      {addOnCheckpoints.map((check) => (
                        <CheckPointListItem key={check.id}>
                          <Image
                            src={icons[check.name.replace("_&", "")]}
                            alt={`${check.actual_name} icon`}
                            width="32px"
                            mr={1}
                          />
                          {check.actual_name}
                        </CheckPointListItem>
                      ))}
                    </CheckPointList>
                  </Box>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ConfirmModal
        isOpen={isConfirmDeleteOpen}
        onRequestClose={() => {
          setIsConfirmDeleteOpen(false);
        }}
        handleConfirm={confirmDeleteMandateType}
        title="Are you sure you want to delete this application type?"
        content="This action cannot be reversed."
      />
    </>
  );
};

export default SettingsApplicationTypeView;
