import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input, InputProps } from "../../components/Input";
import { Loader } from "../../components/Loader";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { useState } from "react";
import { useDPODetails } from "../../data/useDPODetails";
import { useUpdateDPO } from "../../data/useUpdateDPO";
import { useDirtyForm } from "./DirtyFormContext";
import { queryClient } from "../../queryClient";
import { DPO_validation } from "../../utils/validationSchema";
import { DPO } from "../../types/DPO";
import { useCreateDPODetail } from "../../data/useCreateDPODetail";

const SettingsDPO = () => {
  const [doubleClick, setDoubleClick] = useState(false);

  const { data: dpoDetails, isLoading } = useDPODetails();

  const { mutate: updateDPO, isLoading: isUpdatingDPO } = useUpdateDPO();

  const { mutate: createDPODetail } = useCreateDPODetail();

  const { setFormDirty, formRef } = useDirtyForm();

  const handleSubmit = (data: DPO) => {
    setDoubleClick(true);
    setFormDirty(false);

    if (dpoDetails.data.results.length > 0 && dpoDetails?.data?.results[0].id) {
      updateDPO(
        {
          id: dpoDetails?.data?.results[0].id,
          name: data.name,
          email: data.email,
          phone_number: data.phone_number,
        },
        {
          onSuccess: (res) => {
            setDoubleClick(false);
            if (res?.status?.toString().startsWith("2")) {
              notify("DPO details updated", {
                type: "success",
              });
              queryClient.invalidateQueries(["dpoDetails"]);
            }
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(
              err,
              "A problem occurred while updating the dpo details."
            );
          },
        }
      );
    } else {
      createDPODetail(
        {
          name: data.name,
          email: data.email,
          phone_number: data.phone_number,
        },
        {
          onSuccess: (res) => {
            if (res?.status?.toString().startsWith("2")) {
              notify("DPO details created", {
                type: "success",
              });
              queryClient.invalidateQueries(["dpoDetails"]);
              setDoubleClick(false);
            }
          },
          onError: (err: any) => {
            showError(err, "A problem occurred while adding DPO details.");
            setDoubleClick(false);
          },
        }
      );
    }
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <H2>DPO details</H2>

        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                name: dpoDetails?.data?.results[0]?.name || "",
                email: dpoDetails?.data?.results[0]?.email || "",
                phone_number: dpoDetails?.data?.results[0]?.phone_number || "",
              }}
              enableReinitialize
              validate={() => null}
              validationSchema={DPO_validation}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.name}
                        placeholder="Enter name"
                        label="Name"
                        name="name"
                        {...inputProps}
                        isRequired
                        hasError={Boolean(props.errors.name)}
                        errorMessage={props.errors.name}
                      />
                    </Flex>

                    <Flex {...rowProps}>
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.email}
                        placeholder="Enter email address"
                        label="Email address"
                        name="email"
                        {...inputProps}
                        hasError={Boolean(props.errors.email)}
                        errorMessage={props.errors.email}
                      />
                      <Input
                        onChange={(e) => {
                          setFormDirty(true);
                          props.handleChange(e);
                        }}
                        value={props.values.phone_number}
                        placeholder="Enter contact number"
                        label="Contact number"
                        name="phone_number"
                        {...inputProps}
                        hasError={Boolean(props.errors.phone_number)}
                        errorMessage={props.errors.phone_number}
                      />
                    </Flex>
                  </Box>

                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingDPO ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>
  );
};

export default SettingsDPO;
