import styled from "styled-components";
import { Flex } from "../Flex";
import { H2, H3 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Box } from "../Box";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { AlertBox } from "../AlertBox";
import { Image } from "../Image";
import { useChecks } from "../../data/useChecks";
import * as icons from "../../assets/checkpoints";

const ChecksContainer = styled(Box)`
  ${({ theme: { colors, radii, space } }) => `
  background-color: ${colors.gray[10]};
  border-radius: ${radii[0]};
  padding: ${space[1]};
  margin: ${space[1]};
`}
`;

const StyledList = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  ${({ theme: { space } }) => `
  grid-gap: ${space[3]};
  overflow: hidden;
  margin-top: 26px;
  `}
`;

export const ApplicationTypePreviewModal = ({
  onRequestClose,
  isOpen,
  data,
  handleConfirm,
}) => {
  const { data: checkListQuery } = useChecks();
  const checkList = checkListQuery?.data;
  const checks = checkList
    ?.filter((check) => data?.checkpoint.includes(check.id))
    .sort((a, b) => a.order - b.order);

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      <Flex flexDirection="column">
        <H2 m={0} p={0} fontSize={3}>
          Confirm application type creation
        </H2>
        <Box mr={4} pr={2}>
          <AlertBox alertType="info">
            Checks can’t be changed once application type is created.
          </AlertBox>
        </Box>
        <H3 m={0} p={0} fontSize={2}>
          Application Type Name
        </H3>
        <Paragraph m={0} p={0} fontSize={1} fontWeight="400" color="gray.50">
          {data?.name}
        </Paragraph>
        <H3 m={0} p={0} fontSize={2}>
          Description
        </H3>
        <Paragraph
          m={0}
          p={0}
          fontSize={1}
          lineHeight={1.6}
          fontWeight="400"
          color="gray.50"
        >
          {data?.description}
        </Paragraph>
        <H3 m={0} p={0} fontSize={3}>
          Checks
        </H3>
        <ChecksContainer>
          <StyledList>
            {checks?.map((check) => (
              <li key={check.id}>
                <Flex>
                  <Image
                    src={icons[check.name.replace("_&", "") + "_large"]}
                    alt={`${check.actual_name} icon`}
                    width="56px"
                  />
                  <Paragraph ml={2} p={0} fontSize={1} fontWeight="400">
                    {check.actual_name}
                  </Paragraph>
                </Flex>
              </li>
            ))}
          </StyledList>
        </ChecksContainer>
        <Flex mt={4} justifyContent="end">
          <Button mr="2" size="regular" variant="secondary" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button type="submit" size="regular" onClick={handleConfirm}>
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
