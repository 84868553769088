import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { Slider } from "../../components/Slider";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Box } from "../../components/Box";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableRow } from "../../components/Table/TableRow";
import { TableCell } from "../../components/Table/TableCell";
import { Image } from "../../components/Image";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTable,
  CheckTableCell,
  CheckTableHeaderRow,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import {
  IndividualCheckStatus,
  CheckStatusEnum,
} from "../../context/MandateStatusContext";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { IndividualChecksMap } from "./helper";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { ApprovalComment } from "./approvalComment";

export const AddressVerificationCheck = (props) => {
  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.address_verification_check.title}
              </CheckTitle>

              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  title={IndividualChecksMap.address_verification_check.title}
                  id={id}
                ></ManualAcceptReject>
              )}
            </Flex>
            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}
            {individualCheckData?.data?.instance &&
              individualCheckData?.data?.instance?.approval_comment && (
                <ApprovalComment
                  data={individualCheckData?.data?.instance?.approval_comment}
                  status={individualCheckData?.data?.status?.status}
                ></ApprovalComment>
              )}
            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            ></CheckFields>
            {individualCheckData?.data?.instance?.results?.map(
              (instance, index) => (
                <Section key={index}>
                  <Flex
                    py={2}
                    mb={2}
                    borderBottom={"1px solid"}
                    borderColor="gray.10"
                    alignItems="center"
                  >
                    <Box mr={2}>
                      <Image
                        src={
                          instance?.status === IndividualCheckStatus.Passed
                            ? success
                            : inReview
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        mr={1}
                      />
                    </Box>

                    <Box>
                      <H3 mb={0}>{instance?.title}</H3>
                      <CheckParagraph>{instance?.description}</CheckParagraph>
                    </Box>
                  </Flex>

                  <Flex mb={2} alignItems="center">
                    <CheckHeading mr={1}>Result:</CheckHeading>
                    <CheckParagraph>{instance?.status}</CheckParagraph>
                  </Flex>

                  <Flex mb={2} alignItems="center">
                    <CheckHeading mr={1}>Source:</CheckHeading>
                    <CheckParagraph>{instance?.description}</CheckParagraph>
                  </Flex>

                  <Box mb={2}>
                    <CheckHeading>Details:</CheckHeading>
                    <CheckTable>
                      <TableHead></TableHead>
                      <TableBody>
                        <CheckTableHeaderRow>
                          <CheckTableCell>Result</CheckTableCell>
                          <CheckTableCell>Key</CheckTableCell>
                          <CheckTableCell>Reason</CheckTableCell>
                        </CheckTableHeaderRow>
                        {instance?.reasons?.map(
                          ({ status, key, reason }, i) => (
                            <TableRow key={i}>
                              <TableCell
                                width="15%"
                                style={{ textTransform: "uppercase" }}
                                fontWeight="500"
                                color="gray.80"
                                borderBottom="none"
                              >
                                <Flex gap="8">
                                  {/* <Image
                                    src={
                                      status === IndividualCheckStatus.Passed
                                        ? success
                                        : inReview
                                    }
                                    alt="status icon"
                                    height="20px"
                                    width="auto"
                                    mr={1}
                                  /> */}
                                  {status}
                                </Flex>
                              </TableCell>
                              <TableCell
                                width="20%"
                                color="gray.60"
                                borderBottom="none"
                              >
                                {key}
                              </TableCell>
                              <TableCell color="gray.60" borderBottom="none">
                                {reason}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </CheckTable>
                  </Box>
                </Section>
              )
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
