import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { useEffect, useState } from "react";
import { useDirtyForm } from "./DirtyFormContext";
import { useSOFRiskLevel } from "../../data/useSOFRiskLevel";
import { Image } from "../../components/Image";
import { CheckTable } from "../IndividualView/CheckStyledComponents";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableCell } from "../../components/Table/TableCell";
import { TableRow } from "../../components/Table/TableRow";
import { Toggle } from "../../components/Toggle";
import Select from "../../components/Select";
import { useUpdateSOFRiskLevel } from "../../data/useUpdateSOFRiskLevel";
import { notify } from "../../utils/notify";
import { showError } from "../../utils/error-handling";
import { useUpdateSOFActive } from "../../data/useUpdateSOFActive";
import styled from "styled-components";
import { queryClient } from "../../queryClient";
import {
  RiskLevelColors,
  RiskLevelIcons,
} from "../../context/MandateStatusContext";
import { Button } from "../../components/Button";
import { useSOFScoreReset } from "../../data/useSOFScoreReset";
import { SOFRiskLevel } from "../../types/SOFRiskLevel";

export const TableCellStyled = styled.td`
  ${({ theme: { colors, space } }) => ` 
  padding: ${space[1]};
  color: ${colors.gray[50]};
  font-weight: 500;      
 `}
`;

export const TableHeaderRow = styled.tr``;

const SettingsSOFRiskScoring = () => {
  const [sofRiskLevel, setSofRiskLevel] = useState<SOFRiskLevel[]>([]);

  const { data: sofRiskLevelData, isLoading: isSOFRiskLevelLoading } =
    useSOFRiskLevel();

  const { setFormDirty, formRef } = useDirtyForm();

  const { mutate: updateSOFRiskLevel } = useUpdateSOFRiskLevel();

  const { mutate: updateSOFOptionActive } = useUpdateSOFActive();

  const { mutate: resetData } = useSOFScoreReset();

  useEffect(() => {
    if (sofRiskLevelData) {
      setSofRiskLevel(sofRiskLevelData);
    }
  }, [sofRiskLevelData]);

  const handleRiskLevelChange = (id: number, risk_level: string) => {
    updateSOFRiskLevel(
      { id, option: { risk_level } },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            notify("Risk level updated", {
              position: "bottom-left",
              type: "success",
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              autoClose: 15,
            });

            queryClient.invalidateQueries(["sofRiskLevel"]);
          }
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating the risk level.");
        },
      }
    );
  };
  const handleActiveChange = (id: number, active: boolean) => {
    updateSOFOptionActive(
      { id, option: { active } },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            queryClient.invalidateQueries(["sofRiskLevel"]);
          }
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while updating.");
        },
      }
    );
  };

  const getInitialValues = () => {
    if (!sofRiskLevel) return {};
    const initialValues = {};
    sofRiskLevel.forEach((item) => {
      initialValues[`active_${item.id}`] = item.active || false;
      initialValues[`risk_level_${item.id}`] = item.risk_level || "";
    });
    return initialValues;
  };

  const riskOptions = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" },
  ];

  const resetAll = () => {
    resetData(undefined, {
      onSuccess: (res) => {
        setSofRiskLevel(res.data);

        notify("Source of funds risk scoring level have been reset!", {
          position: "bottom-left",
          type: "success",
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          autoClose: 15,
        });
      },
      onError: (err: any) => {
        showError(
          err,
          "A problem occurred while resetting Source of funds risk scoring level."
        );
      },
    });
  };

  return (
    <Box bg="white" borderRadius={0} p={3} mb={3}>
      <Flex justifyContent={"space-between"} alignItems={"center"}>
        <H2 mb={0}>Source of funds risk scoring level</H2>

        <Button
          onClick={resetAll}
          variant="text"
          type="button"
          fontWeight={"bold"}
        >
          Reset all
        </Button>
      </Flex>

      {isSOFRiskLevelLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="35vh">
          <Loader />
        </Flex>
      )}

      {!isSOFRiskLevelLoading && (
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize
          validate={() => null}
          onSubmit={(values, actions) => {
            setFormDirty(false);
            actions.setSubmitting(false);
          }}
          innerRef={formRef}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <CheckTable>
                <TableHead />
                <TableBody>
                  <TableHeaderRow>
                    <TableCellStyled color="gray.50">Name</TableCellStyled>
                    <TableCellStyled color="gray.50">
                      Risk level
                    </TableCellStyled>
                    <TableCellStyled color="gray.50">Active</TableCellStyled>
                  </TableHeaderRow>
                  {sofRiskLevel?.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell
                        fontWeight="500"
                        borderBottom="1px solid"
                        borderColor="gray.10"
                      >
                        <Flex gap="50">{item.option.name}</Flex>
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="1px solid"
                        borderColor="gray.10"
                      >
                        <Select
                          value={
                            riskOptions.find(
                              (option) =>
                                option.value ===
                                props.values[`risk_level_${item.id}`]
                            ) || riskOptions[0]
                          }
                          getOptionLabel={(option) => (
                            <Flex gap="12">
                              <Image
                                src={RiskLevelIcons[option.value]}
                                alt="status icon"
                                height="20px"
                                width="20px"
                              />
                              <Box
                                width="70px"
                                color={RiskLevelColors[option.value]}
                              >
                                {option.label}
                              </Box>
                            </Flex>
                          )}
                          options={riskOptions}
                          onChange={(option) => {
                            props.setFieldValue(
                              `risk_level_${item.id}`,
                              option.value
                            );
                            handleRiskLevelChange(
                              item.id,
                              option.value as string
                            );
                            setFormDirty(true);
                          }}
                        />
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="1px solid"
                        borderColor="gray.10"
                      >
                        <Toggle
                          isChecked={props.values[`active_${item.id}`] || false}
                          onChange={(e) => {
                            props.setFieldValue(
                              `active_${item.id}`,
                              e.target.checked
                            );
                            handleActiveChange(item.id, e.target.checked);
                            setFormDirty(true);
                          }}
                          mt={2}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CheckTable>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default SettingsSOFRiskScoring;
