import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { AdditionalDocumentsType } from "../types/AdditionalDocumentsType";

const fetch = (authHeader: string, individualId: number) => () =>
  axios.get<AdditionalDocumentsType[]>(
    `${env.verifyServiceUrl}individual/individual_v2/${individualId}/additional_document/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useAdditionalDocuments = (id) => {
  const { authHeader } = useAuth();
  return useQuery(["additionalDocuments", id], fetch(authHeader, id), {
    retry: false,
  });
};
