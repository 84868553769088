import { Box } from "../../components/Box";
import { GrayCard } from "../../components/Card";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { Paragraph } from "../../components/Paragraph";
import { Slider } from "../../components/Slider";
import { TableBody } from "../../components/Table/TableBody";
import { TableCell } from "../../components/Table/TableCell";
import { TableHead } from "../../components/Table/TableHead";
import { TableRow } from "../../components/Table/TableRow";
import {
  IndividualCheckStatus,
  CheckStatusEnum,
} from "../../context/MandateStatusContext";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTable,
  CheckTableCell,
  CheckTableHeaderRow,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { useGetImages } from "../../data/useGetImages";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { IndividualChecksMap } from "./helper";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { ApprovalComment } from "./approvalComment";
import theme from "../../theme";

export const IdentityLivenessCheck = (props) => {
  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  const { data: whiteimages } = useGetImages(
    instance?.images?.map((image) => (image?.path ? image?.path : ""))
  );

  const { data: livenessimages } = useGetImages(
    instance?.liveness?.images?.map((image) => (image?.path ? image?.path : ""))
  );

  const { data: face_match_urls } = useGetImages(
    instance?.face_match?.images.reduce(
      (result, image) =>
        image.path ? result.push(image.path) && result : result,
      []
    )
  );

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap["id_&_liveliness_check"].title}
              </CheckTitle>

              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  id={id}
                  title={IndividualChecksMap["id_&_liveliness_check"].title}
                ></ManualAcceptReject>
              )}
            </Flex>

            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}

            {instance && instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              ></ApprovalComment>
            )}

            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            ></CheckFields>

            {instance && (
              <div>
                <Section>
                  {instance?.document_title && (
                    <Paragraph fontWeight={600}>
                      {instance?.document_title}
                    </Paragraph>
                  )}
                  <Flex gap="32" alignItems="flex-start">
                    {instance?.header && (
                      <GrayCard m="0" flex={0.6}>
                        <CheckHeading>Status:</CheckHeading>
                        <Paragraph mt={1}>
                          {instance?.header?.status
                            ? instance?.header?.status
                            : "-"}
                        </Paragraph>
                        <CheckHeading>Document type:</CheckHeading>
                        <Paragraph mt={1}>
                          {instance?.header?.document_type
                            ? instance?.header?.document_type
                            : "-"}
                        </Paragraph>

                        <CheckHeading>Document name:</CheckHeading>
                        <Paragraph mt={1}>
                          {instance?.header?.document_name
                            ? instance?.header?.document_name
                            : "-"}
                        </Paragraph>
                        <CheckHeading>Country name:</CheckHeading>
                        <Paragraph mt={1}>
                          {instance?.header?.country_name
                            ? instance?.header?.country_name
                            : "-"}
                        </Paragraph>

                        <CheckHeading>Initiated date: </CheckHeading>
                        <Paragraph mt={1}>
                          {instance?.header?.initiated_date
                            ? instance?.header?.initiated_date
                            : "-"}
                        </Paragraph>
                      </GrayCard>
                    )}
                    <Box flex={1.4}>
                      {whiteimages && (
                        <Image
                          width="100%"
                          borderRadius="20px"
                          src={`data:image/jpg;base64,${whiteimages[0]}`}
                          alt="liveness image"
                        />
                      )}
                    </Box>
                  </Flex>

                  <Flex gap="0 24" flexWrap="wrap" mt={4}>
                    {instance?.summary?.map(
                      (summary, i) =>
                        summary?.value && (
                          <Box flex="48%" key={i}>
                            <CheckHeading>{summary.property} </CheckHeading>
                            <Paragraph mt={1}>{summary.value}</Paragraph>
                          </Box>
                        )
                    )}
                  </Flex>
                </Section>

                {instance?.personal_detail_match && (
                  <Section>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box flex={1}>
                        <H3 mb={2}>
                          <Image
                            src={
                              instance?.personal_detail_match?.status ===
                              IndividualCheckStatus.Passed
                                ? success
                                : inReview
                            }
                            alt="status icon"
                            height="20px"
                            width="auto"
                            mr={1}
                          />
                          {instance?.personal_detail_match?.title} -{" "}
                          {instance?.personal_detail_match?.status}
                        </H3>
                      </Box>
                      <Box flex={1}>
                        <Flex justifyContent={"space-between"}>
                          <Box flex={0.3}>
                            <CheckHeading>Result:</CheckHeading>
                          </Box>
                          <Box flex={1.7}>
                            <CheckHeading>
                              {instance?.personal_detail_match?.status}
                            </CheckHeading>
                          </Box>
                        </Flex>

                        <Flex justifyContent={"space-between"}>
                          <Box flex={0.3}>
                            <CheckHeading>Reason:</CheckHeading>
                          </Box>
                          <Box flex={1.7}>
                            <Paragraph my={0}>
                              {instance?.personal_detail_match?.result}
                            </Paragraph>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>

                    <Box>
                      <Paragraph fontSize={1}>Details</Paragraph>
                      <CheckTable>
                        <TableHead></TableHead>
                        <TableBody>
                          <CheckTableHeaderRow>
                            <CheckTableCell></CheckTableCell>
                            <CheckTableCell>Personal Details</CheckTableCell>
                            <CheckTableCell>ID Document Details</CheckTableCell>
                          </CheckTableHeaderRow>
                          {instance?.personal_detail_match?.details?.map(
                            (
                              { iDDocumentDetails, personalDetails, property },
                              i
                            ) => (
                              <TableRow key={i}>
                                <TableCell
                                  // style={{ textTransform: "uppercase" }}
                                  fontWeight="600"
                                  color="gray.80"
                                  borderBottom="none"
                                >
                                  {property}
                                </TableCell>
                                <TableCell color="gray.60" borderBottom="none">
                                  {personalDetails ? personalDetails : "-"}
                                </TableCell>
                                <TableCell color="gray.60" borderBottom="none">
                                  {iDDocumentDetails ? iDDocumentDetails : "-"}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </CheckTable>
                    </Box>
                  </Section>
                )}
                {instance?.verified_document && (
                  <Section>
                    <H3 mb={2}>
                      <Image
                        src={
                          instance?.verified_document?.status ===
                          IndividualCheckStatus.Passed
                            ? success
                            : inReview
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        mr={1}
                      />
                      {instance?.verified_document?.title} -{" "}
                      {instance?.verified_document?.status}
                    </H3>

                    <Flex mb={2} alignItems="center">
                      <CheckHeading mr={1}>Recognised document:</CheckHeading>
                      <CheckParagraph>
                        {instance?.verified_document?.recognised_document
                          ? instance?.verified_document?.recognised_document
                          : "-"}
                      </CheckParagraph>
                    </Flex>
                    <Flex mb={2} alignItems="center">
                      <CheckHeading mr={1}>Expected document:</CheckHeading>
                      <CheckParagraph>
                        {instance?.verified_document?.expected_document
                          ? instance?.verified_document?.expected_document
                          : "-"}
                      </CheckParagraph>
                    </Flex>

                    <Box mb={2}>
                      <CheckHeading>Details:</CheckHeading>
                      <CheckTable>
                        <TableHead></TableHead>
                        <TableBody>
                          <CheckTableHeaderRow>
                            <CheckTableCell>Result</CheckTableCell>
                            <CheckTableCell>Check</CheckTableCell>
                          </CheckTableHeaderRow>
                          {instance?.details?.map(({ status, check }, i) => (
                            <TableRow key={i}>
                              <TableCell
                                style={{ textTransform: "uppercase" }}
                                fontWeight="500"
                                color="gray.80"
                                borderBottom="none"
                              >
                                {status}
                              </TableCell>
                              <TableCell color="gray.60" borderBottom="none">
                                {check}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </CheckTable>
                    </Box>
                  </Section>
                )}

                {instance?.liveness && (
                  <Section mb={2}>
                    <Flex>
                      <Image
                        src={
                          instance?.liveness?.status ===
                          IndividualCheckStatus.Passed
                            ? success
                            : inReview
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        paddingTop="5px"
                        mr={1}
                      />

                      <H3 mb={2}>
                        {instance?.liveness?.title} -{" "}
                        {instance?.liveness?.status}
                      </H3>
                    </Flex>
                    {/* <Box mb={2}>
                      <CheckHeading>
                        Liveness overall failure reason:
                      </CheckHeading>
                      <CheckParagraph>
                        {instance?.liveness?.reason_code
                          ? instance?.liveness?.reason_code
                          : "-"}
                      </CheckParagraph>
                    </Box> */}

                    <Flex gap="32" mb={3}>
                      {instance?.liveness?.images?.map((image, i) => (
                        <Box key={i} flex={1}>
                          {livenessimages && (
                            <Image
                              width="100%"
                              borderRadius="20px"
                              src={`data:image/jpg;base64,${livenessimages[i]}`}
                              alt="liveness image"
                            />
                          )}
                          <CheckHeading>{image.name} </CheckHeading>

                          <Flex alignItems="center">
                            <Paragraph m={0}>{image.status}</Paragraph>
                          </Flex>
                        </Box>
                      ))}
                    </Flex>
                  </Section>
                )}
                {instance?.face_match && (
                  <Flex gap="2">
                    <Image
                      src={
                        instance?.face_match?.status ===
                        IndividualCheckStatus.Passed
                          ? success
                          : inReview
                      }
                      alt="status icon"
                      width="auto"
                      paddingLeft="30px"
                      paddingBottom="10px"
                      mr={1}
                    />
                    <H3 mb={2}>
                      {" "}
                      {instance?.face_match?.title} -{" "}
                      {instance?.face_match?.status}
                    </H3>
                  </Flex>
                )}

                {(instance?.face_match?.images ||
                  instance?.face_match?.results) && (
                  <Section>
                    <Flex gap="24">
                      <Box flex={1}>
                        <Flex gap="24">
                          {instance?.face_match?.images?.map((image, i) => (
                            <Box key={i} flex={1}>
                              <Paragraph fontWeight="bold">
                                {image?.name}
                              </Paragraph>
                              {face_match_urls && face_match_urls[i] && (
                                <Image
                                  width="100%"
                                  borderRadius="20px"
                                  src={`data:image/jpg;base64,${face_match_urls[i]}`}
                                  alt="liveness image"
                                />
                              )}
                            </Box>
                          ))}
                        </Flex>
                      </Box>

                      <Box flex={1} mb={2}>
                        <GrayCard>
                          <CheckHeading>Review reason</CheckHeading>
                          <hr color={theme.colors.gray[100]}></hr>
                          {instance?.face_match?.results?.length > 0 ? (
                            instance?.face_match?.results?.map((reason, i) => (
                              <Paragraph key={i} my={1}>
                                {reason}
                              </Paragraph>
                            ))
                          ) : (
                            <Paragraph my={1}>None</Paragraph>
                          )}
                        </GrayCard>
                      </Box>
                    </Flex>
                  </Section>
                )}
              </div>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
