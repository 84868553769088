import { H2 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { IndividualCard } from "../../components/IndividualCard";
import { Flex } from "../../components/Flex";
import { Button } from "../../components/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useIndividuals } from "../../data/useIndividuals";
import { Individual } from "../../types/Individual";
import { useCreateIndividualsInvites } from "../../data/useCreateIndividualsInvites";
import { displayError, logError } from "../../utils/error-handling";
import { Modal } from "../../components/Modal";
import { useState } from "react";
import { queryClient } from "../../queryClient";
import { NewApplicationFrame } from "./NewApplicationFrame";

export const SendInvites = ({
  mandateId,
  onClickBack,
}: {
  mandateId?: number;
  onClickBack: () => void;
}) => {
  let location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: individuals } = useIndividuals(
    location.state ? (location.state as number) : mandateId
  );

  const { mutate: createIndividualsInvites, isLoading } =
    useCreateIndividualsInvites();

  const handleSendInvites = () => {
    const individualsIds = individuals?.data.results.map(({ id }) => id);

    createIndividualsInvites(
      {
        individual_ids: [...individualsIds],
        id: location.state ? (location.state as number) : mandateId,
      },
      {
        onSuccess: () => {
          setIsModalOpen(true);
          queryClient.invalidateQueries(["mandate", mandateId]);
        },
        onError: (err: any) => {
          setIsModalOpen(false);
          displayError("A problem occurred while sending invite(s).");
          logError(err);
        },
      }
    );
  };

  return (
    <NewApplicationFrame step="invites" mandateId={mandateId}>
      <H2>Send Invites</H2>
      <Paragraph>
        Please add the individuals you would like to complete verifications.
      </Paragraph>
      <Flex overflowY="auto" flexDirection="column" maxHeight="60vh" gap="15">
        {individuals?.data?.results?.map(
          ({
            first_name,
            middle_name,
            last_name,
            date_of_birth,
            email,
            id,
          }: Individual) => (
            <IndividualCard
              key={id}
              firstName={first_name}
              middleName={middle_name}
              lastName={last_name}
              dateOfBirth={date_of_birth}
              email={email}
              hasRemoveButton={false}
            />
          )
        )}
      </Flex>
      <Flex justifyContent="space-between">
        <Button
          size="large"
          mt="3"
          mb="0"
          variant="secondary"
          onClick={() => onClickBack()}
        >
          Back
        </Button>
        <Button
          size="large"
          mt="3"
          mb="0"
          onClick={handleSendInvites}
          isDisabled={isLoading}
        >
          Save & Send Invites
        </Button>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => {
              navigate(`/applications/${mandateId}`);
              setIsModalOpen(!isModalOpen);
            }}
            title="Your invitations have been sent successfully!"
            primaryButtonText="Continue"
            hasIcon={true}
            isSuccessful={true}
            onModalConfirmClick={async () => {
              await navigate(`/applications/${mandateId}`, { replace: true });
            }}
            hasCloseIcon={false}
          />
        )}
      </Flex>
    </NewApplicationFrame>
  );
};
