import styled from "styled-components";
import { fadeIn } from "../animation";
import { Paragraph } from "../Paragraph";
import type { Checkpoint } from "../../types/Check";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Image } from "../Image";
import * as icons from "../../assets/checkpoints";
import { Button } from "../Button";
import { FaPlus, FaTrash } from "react-icons/fa";
import { useState } from "react";
import classnames from "classnames";

const Wrapper = styled(Box)`
  animation: fadein 0.25s;
  ${fadeIn}

  ${({ theme: { colors, radii, space } }) => `
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-decoration: none;
      color: inherit;
      background: ${colors.gray[10]};
      border-radius: ${radii[1]};
      padding: ${space[2]};
      position: relative;
      transition: all 0.2s ease 0s;
      border: 1px solid white;

      &:active {
        transform: scale(1, 1);
      }
  `}
`;
const StyledDiv = styled.div`
  ${({ theme: { colors } }) => `
  &.light-grey > button {
    background-color: ${colors.gray[10]} !important;
    border: none !important;
  }
  `}
`;

interface CheckCardProps extends Checkpoint {
  handleCheckpoints: (id: number, isAdding: boolean) => void;
}

export const CheckCard = (props: CheckCardProps) => {
  const { id, name, actual_name, check_type } = props;
  const [isAdded, setIsAdded] = useState(name === "geo_location_check");

  const imageName = name.replace("_&", "") + "_large";

  return (
    <Wrapper>
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex
          justifyContent="space-between"
          flexDirection={check_type === "CORE" ? "column" : "row"}
        >
          <Image src={icons[imageName]} alt={`${actual_name} icon`} width="56px" />
          <Paragraph pl={check_type === "CORE" ? "" : "2"}>
            {actual_name}{" "}
            {name === "geo_location_check" ? (
              <span style={{ color: "grey" }}>(Optional)</span>
            ) : null}
          </Paragraph>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent={check_type === "CORE" ? "start" : "center"}
        >
          {check_type === "ADDON" || name === "geo_location_check" ? (
            <StyledDiv className={classnames({ "light-grey": isAdded })}>
              <Button
                Icon={isAdded ? FaTrash : FaPlus}
                iconSize="16px"
                size="regular"
                onClick={() => {
                  props.handleCheckpoints(id, !isAdded);
                  setIsAdded(!isAdded);
                }}
                variant="secondary"
                border="1.5px solid #CCD3E0"
              >
                {isAdded ? "Remove" : "Add"}
              </Button>
            </StyledDiv>
          ) : null}
        </Flex>
      </Flex>
    </Wrapper>
  );
};
