import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { Paragraph } from "../../components/Paragraph";
import { useTenantFilter } from "../../data/useTenantFilter";
import { Image } from "../../components/Image";
import allDOB from "../../assets/settings/dob/dob-all.svg";
import person from "../../assets/settings/person.svg";
import gender from "../../assets/settings/gender.svg";
import includepep from "../../assets/settings/include-pep.svg";
import excludepep from "../../assets/settings/exclude-pep.svg";
import adversemedia from "../../assets/settings/adversemedia.svg";
import exactDOB from "../../assets/settings/dob/dob-exact.svg";
import excludeDOB from "../../assets/settings/dob/dob-exclude.svg";
import yearMatchDOB from "../../assets/settings/dob/dob-year.svg";
import { Toggle } from "../../components/Toggle";
import { Form, Formik } from "formik";
import styled from "styled-components";
import CustomRangeSlider from "../../components/CustomRangeSlider";
import RangeSlider from "../../components/RangeSlider";
import {
  FilterOption,
  NameMatchMethod,
  NameMatchType,
  Subcategory,
  TenantFilter,
  TenantFilterPayload,
} from "../../types/TenantFilter";
import { Button } from "../../components/Button";
import { useUpdateTenantFilters } from "../../data/useUpdateTenantFilters";
import { notify } from "../../utils/notify";
import { queryClient } from "../../queryClient";
import { showError } from "../../utils/error-handling";
import { useEffect, useState } from "react";
import { useMultipleFilterOptions } from "../../data/useFilterOptions";
import { useDirtyForm } from "./DirtyFormContext";
import { useFilterReset } from "../../data/useFilterReset";
import { groupBySubcategory } from "../../utils/helpers";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
    margin-bottom: 0px;
  `}
`;

const StyleParagraph = styled(Paragraph)`
  ${({ theme: { fontSizes, colors, space } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
    color: ${colors.gray[60]};
    margin-top: ${space[0]};
    margin-bottom: ${space[0]};
  `}
`;

const FormSection = ({ title, children }) => (
  <Box mb={4}>
    <H3 fontSize="16px" mb={3}>
      {title}
    </H3>
    {children}
  </Box>
);

const FilterToggle = ({
  src,
  alt,
  heading,
  paragraph,
  isChecked,
  onChange,
}) => (
  <Flex justifyContent="space-between" alignItems="center" mb={2}>
    <Flex gap="16">
      <Image src={src} alt={alt} />
      <Box>
        <Heading>{heading}</Heading>
        <StyleParagraph>{paragraph}</StyleParagraph>
      </Box>
    </Flex>
    <Toggle isChecked={isChecked} onChange={onChange} mt={0} />
  </Flex>
);

const SettingsPEPSanctions = () => {
  const [doubleClick, setDoubleClick] = useState(false);

  const [filterData, setFilterData] = useState<TenantFilter | null>(null); // Initialize filterData state

  const { setFormDirty, formRef } = useDirtyForm();

  const { data: tenantFilterData, isLoading } = useTenantFilter();

  const { data: filterOptionsData, isLoading: isFilterOptionsLoading } =
    useMultipleFilterOptions(["pep_types", "adverse_media_types"]);

  const { mutate: updateTenantFilters, isLoading: isUpdatingFilters } =
    useUpdateTenantFilters();

  const { mutate: resetData, isLoading: isResetDataLoading } = useFilterReset();

  useEffect(() => {
    if (tenantFilterData) {
      setFilterData(tenantFilterData.data);
    }
  }, [tenantFilterData]);

  const groupedOptions =
    filterOptionsData && filterOptionsData.length > 1
      ? groupBySubcategory(filterOptionsData[1])
      : [];

  const handleSubmit = (data: TenantFilterPayload) => {
    setDoubleClick(true);
    setFormDirty(false);

    if (data.include_all_dob) {
      data.include_year_only_dob = false;
      data.include_year_only_dob_tolerance = 0;
      data.include_full_dob = false;
      data.exclude_if_source_dob_unknown = false;
    }
    if (!data.include_all_dob && data.include_full_dob) {
      data.include_year_only_dob = false;
      data.include_year_only_dob_tolerance = 0;
    }
    if (data.name_match_method === NameMatchMethod.Exact) {
      data.name_match_method_tolerance = 80;
      data.name_match_type = NameMatchType.Full;
    }
    if (data.include_all_adverse_media_types) {
      data.exclude_adverse_media_types = [];
    }
    if (data.include_all_pep_types) {
      data.exclude_pep_types = [];
      // data.exclude_pep_by_family_association = false;
    }

    updateTenantFilters(data, {
      onSuccess: (res) => {
        setDoubleClick(false);
        if (res?.status?.toString().startsWith("2")) {
          notify("Your settings have been saved!", {
            type: "success",
          });
          queryClient.invalidateQueries(["tenantFilter"]);
        }
      },
      onError: (err: any) => {
        setDoubleClick(false);
        showError(err, "A problem occurred while updating filters.");
      },
    });
  };

  const resetAll = () => {
    resetData(undefined, {
      onSuccess: (res) => {
        setDoubleClick(false);
        setFilterData(res.data); // Update filterData state with the response data
        notify("Your settings have been reset!", {
          type: "success",
        });
      },
      onError: (err: any) => {
        setDoubleClick(false);
        showError(err, "A problem occurred while resetting filters.");
      },
    });
  };

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <H2 mb={0}>PEP & Sanctions and Adverse Media Filters</H2>

          <Button onClick={resetAll} variant="text" type="button" fontWeight={"bold"}>
            Reset all
          </Button>
        </Flex>
        <Paragraph color={"gray.60"} fontSize={1} mt={"0"} mb={3}>
          Customise the sensitivity of PEP & Sanctions and Adverse Media
          screening to suit your compliance needs.
        </Paragraph>

        {(isLoading || isResetDataLoading) && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {(!isLoading && !isResetDataLoading) && filterData && (
          <>
            <Formik
              initialValues={{
                include_all_dob: filterData?.by_dob?.include_all_dob,
                include_full_dob: filterData?.by_dob?.include_full_dob,
                include_year_only_dob:
                  filterData?.by_dob?.include_year_only_dob,
                include_year_only_dob_tolerance:
                  filterData?.by_dob?.include_year_only_dob_tolerance,
                exclude_if_source_dob_unknown:
                  filterData?.by_dob?.exclude_if_source_dob_unknown,
                name_match_method: filterData?.by_name?.name_match_method,
                name_match_type: filterData?.by_name?.name_match_type,
                name_match_method_tolerance:
                  filterData?.by_name?.name_match_method_tolerance,
                exclude_gender_match:
                  filterData?.by_gender?.exclude_gender_match,
                include_all_pep_types:
                  filterData?.by_pep_type?.include_all_pep_types,
                // exclude_pep_by_family_association:
                //   filterData?.by_pep_type?.exclude_pep_by_family_association,
                exclude_pep_types:
                  filterData?.by_pep_type?.exclude_pep_types || [],
                include_all_adverse_media_types:
                  filterData?.by_adverse_media_type
                    ?.include_all_adverse_media_types,
                exclude_adverse_media_types:
                  filterData?.by_adverse_media_type
                    ?.exclude_adverse_media_types || [],
              }}
              enableReinitialize={true}
              onSubmit={(values, actions) => {
                handleSubmit(values);
                actions.setSubmitting(false);
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <FormSection title="Date of Birth Filters">
                    <FilterToggle
                      src={allDOB}
                      alt="all dob"
                      heading="Include all results"
                      paragraph="Include all results regardless of if the source has a date of birth available or matches."
                      isChecked={props.values.include_all_dob}
                      onChange={(e) => {
                        setFormDirty(true);
                        props.setFieldValue(
                          "include_all_dob",
                          e.target.checked
                        );
                      }}
                    />

                    {!props.values.include_all_dob && (
                      <>
                        <FilterToggle
                          src={excludeDOB}
                          alt="excludeDOB"
                          heading="Exclude if date of birth unknown"
                          paragraph="Exclude result if the date of birth is not
                  available within the source."
                          isChecked={props.values.exclude_if_source_dob_unknown}
                          onChange={(e) => {
                            setFormDirty(true);
                            props.setFieldValue(
                              "exclude_if_source_dob_unknown",
                              e.target.checked
                            );
                          }}
                        />

                        <FilterToggle
                          src={exactDOB}
                          alt="exactDOB"
                          heading="Match exact date of birth"
                          paragraph="Results must have an exact date of birth match
                  if the date of birth is available in the source."
                          isChecked={props.values.include_full_dob}
                          onChange={(e) => {
                            setFormDirty(true);
                            props.setFieldValue(
                              "include_full_dob",
                              e.target.checked
                            );
                          }}
                        />
                      </>
                    )}

                    {!props.values.include_all_dob &&
                      !props.values.include_full_dob && (
                        <>
                          <FilterToggle
                            src={yearMatchDOB}
                            alt="yearMatchDOB"
                            heading="Match year of birth"
                            paragraph="The date of birth year must be within a set
                  tolerance if available in the source."
                            isChecked={props.values.include_year_only_dob}
                            onChange={(e) => {
                              setFormDirty(true);
                              props.setFieldValue(
                                "include_year_only_dob",
                                e.target.checked
                              );
                            }}
                          />

                          <Flex gap="25" justifyContent={"space-between"}>
                            <Box>
                              <Heading>Year of birth tolerance</Heading>
                              <StyleParagraph>
                                Please set the tolerance of year of birth.
                              </StyleParagraph>
                            </Box>
                            <Box width={"300px"}>
                              <CustomRangeSlider
                                toleranceValue={
                                  props.values.include_year_only_dob_tolerance
                                }
                                onChange={(value: number) => {
                                  setFormDirty(true);
                                  props.setFieldValue(
                                    "include_year_only_dob_tolerance",
                                    value
                                  );
                                }}
                              />
                            </Box>
                          </Flex>
                        </>
                      )}
                  </FormSection>

                  <FormSection title="Name Match Filters">
                    <FilterToggle
                      src={person}
                      alt="person"
                      heading="Exact name required"
                      paragraph="Requires an exact match with the first and last name"
                      isChecked={
                        props.values.name_match_method === NameMatchMethod.Exact
                      }
                      onChange={(e: any) => {
                        setFormDirty(true);
                        props.setFieldValue(
                          "name_match_method",
                          e.target.checked
                            ? NameMatchMethod.Exact
                            : NameMatchMethod.Fuzzy
                        );
                      }}
                    />

                    {props.values.name_match_method ===
                      NameMatchMethod.Fuzzy && (
                      <>
                        <FilterToggle
                          src={person}
                          alt="person"
                          heading="Surname only"
                          paragraph="Fuzzy match on surname only"
                          isChecked={
                            props.values.name_match_type ===
                            NameMatchType.Surname
                          }
                          onChange={(e: any) => {
                            setFormDirty(true);
                            props.setFieldValue(
                              "name_match_type",
                              e.target.checked
                                ? NameMatchType.Surname
                                : NameMatchType.Full
                            );
                          }}
                        />

                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Box>
                            <Heading>Fuzzy matching sensitivity</Heading>
                            <StyleParagraph>
                              Controls how strictly the source name needs to
                              match with the name provided.
                            </StyleParagraph>
                          </Box>
                          <Box width={"300px"}>
                            <RangeSlider
                              toleranceValue={
                                props.values.name_match_method_tolerance
                              }
                              min={80}
                              max={100}
                              onChange={(value: number) => {
                                setFormDirty(true);
                                props.setFieldValue(
                                  "name_match_method_tolerance",
                                  value
                                );
                              }}
                            />
                          </Box>
                        </Flex>
                      </>
                    )}
                  </FormSection>

                  <FormSection title="Gender Match Filters">
                    <FilterToggle
                      src={gender}
                      alt="gender"
                      heading="Require exact gender match"
                      paragraph="If gender is available in the source it must exactly
                          match with the provided gender."
                      isChecked={!props.values.exclude_gender_match}
                      onChange={(e: any) => {
                        setFormDirty(true);
                        props.setFieldValue(
                          "exclude_gender_match",
                          !e.target.checked
                        );
                      }}
                    />
                  </FormSection>

                  <FormSection title="PEP Type Filters">
                    <FilterToggle
                      src={includepep}
                      alt="includepep"
                      heading="Include all PEP types"
                      paragraph="Will include all types PEP types in the results."
                      isChecked={props.values.include_all_pep_types}
                      onChange={(e: any) => {
                        setFormDirty(true);
                        props.setFieldValue(
                          "include_all_pep_types",
                          e.target.checked
                        );
                      }}
                    />

                    {!props.values.include_all_pep_types && (
                      <>
                        {/* <FilterToggle
                          src={excludepep}
                          alt="excludepep"
                          heading="Exclude PEP by family association"
                          paragraph="Result will be excluded if only a PEP by
                        association."
                          isChecked={
                            props.values.exclude_pep_by_family_association
                          }
                          onChange={(e: any) => {
                            setFormDirty(true);
                            props.setFieldValue(
                              "exclude_pep_by_family_association",
                              e.target.checked
                            );
                          }}
                        /> */}

                        <Paragraph>Select PEP types</Paragraph>

                        {!isFilterOptionsLoading &&
                          filterOptionsData &&
                          filterOptionsData[0].map(
                            (peptypes: FilterOption, index: number) => (
                              <Flex key={index} justifyContent="space-between" alignItems={"center"}>
                                <Paragraph fontWeight={500} color={"gray.80"} fontSize={1}>
                                  {peptypes.name} - {peptypes.label}
                                </Paragraph>

                                <Toggle
                                  isChecked={
                                    props.values.exclude_pep_types &&
                                    props.values.exclude_pep_types.indexOf(
                                      peptypes.name
                                    ) === -1
                                  }
                                  onChange={(e: any) => {
                                    setFormDirty(true);
                                    let newExcludePepTypes = [
                                      ...(props.values.exclude_pep_types || []),
                                    ];
                                    if (e.target.checked) {
                                      const index = newExcludePepTypes.indexOf(
                                        peptypes.name
                                      );
                                      if (index > -1) {
                                        newExcludePepTypes.splice(index, 1);
                                      }
                                    } else {
                                      newExcludePepTypes.push(peptypes.name);
                                    }
                                    props.setFieldValue(
                                      "exclude_pep_types",
                                      newExcludePepTypes
                                    );
                                  }}
                                  mt={0}
                                />
                              </Flex>
                            )
                          )}
                      </>
                    )}
                  </FormSection>

                  <FormSection title="Adverse Media Type Filters">
                    <FilterToggle
                      src={adversemedia}
                      alt="adversemedia"
                      heading="Include all adverse media types"
                      paragraph="Will include all adverse media types in the results."
                      isChecked={props.values.include_all_adverse_media_types}
                      onChange={(e: any) => {
                        setFormDirty(true);
                        props.setFieldValue(
                          "include_all_adverse_media_types",
                          e.target.checked
                        );
                      }}
                    />

                    {!props.values.include_all_adverse_media_types && (
                      <Box>
                        {Object.entries(groupedOptions).map(
                          ([subcategory, options]) => (
                            <Box key={subcategory}>
                              <StyleParagraph>
                                {Subcategory[subcategory]}
                              </StyleParagraph>
                              {options.map((option) => (
                                <Flex
                                  key={option.name}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <Paragraph fontWeight={500} color={"gray.80"} fontSize={1}>
                                    {option.name} - {option.label}
                                  </Paragraph>
                                  <Toggle
                                    isChecked={
                                      props.values
                                        .exclude_adverse_media_types &&
                                      props.values.exclude_adverse_media_types.indexOf(
                                        option.name
                                      ) === -1
                                    }
                                    onChange={(e: any) => {
                                      setFormDirty(true);
                                      let newExcludeAdverseMediaTypes = [
                                        ...(props.values
                                          .exclude_adverse_media_types || []),
                                      ];
                                      if (e.target.checked) {
                                        const index =
                                          newExcludeAdverseMediaTypes.indexOf(
                                            option.name
                                          );
                                        if (index > -1) {
                                          newExcludeAdverseMediaTypes.splice(
                                            index,
                                            1
                                          );
                                        }
                                      } else {
                                        newExcludeAdverseMediaTypes.push(
                                          option.name
                                        );
                                      }
                                      props.setFieldValue(
                                        "exclude_adverse_media_types",
                                        newExcludeAdverseMediaTypes
                                      );
                                    }}
                                    mt={0}
                                  />
                                </Flex>
                              ))}
                            </Box>
                          )
                        )}
                      </Box>
                    )}
                  </FormSection>
                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          doubleClick ||
                          isUpdatingFilters
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </>
  );
};

export default SettingsPEPSanctions;
