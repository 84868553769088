import { Slider } from "./../Slider/index";
import { Box } from "../Box";
import { Flex } from "../Flex";
import { Loader } from "../Loader";
import { H2, H3 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { DateTime } from "luxon";
import styled from "styled-components";
import downloadIcon from "../../assets/download-blue.svg";
import noDocuments from "../../assets/no-documents.svg";
import { Button } from "../Button";
import { useState } from "react";
import { clearAll, notify } from "../../utils/notify";
import axios, { AxiosResponse } from "axios";
import { Image } from "../Image";
import { useAuth } from "../../context/AuthContext";
import { AdditionalDocumentsType } from "../../types/AdditionalDocumentsType";
import { CheckContainer } from "../../pages/IndividualView/CheckStyledComponents";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
  `}
`;

const StyleParagraph = styled(Paragraph)`
  ${({ theme: { fontSizes, colors, space } }) => `
    font-size: ${fontSizes[1]};
    word-break: break-all;
    color: ${colors.gray[60]};
    margin-top: ${space[0]};
    margin-bottom: ${space[0]};
  `}
`;

type AdditionalDocumentsProps = {
  isOpen?: boolean;
  onRequestClose: () => void;
  documents?: AxiosResponse<AdditionalDocumentsType[]>;
  documentsLoading: boolean;
};

export const AdditionalDocuments = (props: AdditionalDocumentsProps) => {
  const { isOpen, onRequestClose, documents, documentsLoading } = props;

  const { authHeader } = useAuth();

  // Individual download state for each document
  const [downloadStates, setDownloadStates] = useState(
    documents?.data?.map(() => false) || []
  );

  const [isRequestingDownloadAll, setIsRequestingDownloadAll] = useState(false);

  // Function to extract filename from URL
  const getFilenameFromUrl = (url) => {
    // Create a new URL object
    const urlObj = new URL(url);
    // Use pathname to get the path and split by "/" to get the last segment
    const segments = urlObj.pathname.split("/");
    // Return the last segment which is the filename
    return segments.pop();
  };

  const handleDownloadAll = async () => {
    if (isRequestingDownloadAll) {
      return;
    }

    setIsRequestingDownloadAll(true);
    notify("Downloading all, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: false,
    });

    // Update download states for all documents
    setDownloadStates((prevStates) => prevStates.fill(true));

    const downloadPromises = documents?.data.map((source, index) => {
      return handleDownload(source.document_url, true, index);
    });

    try {
      await Promise.all(downloadPromises);

      setIsRequestingDownloadAll(false); // Update state after all downloads are complete
      clearAll();
      notify("All documents downloaded successfully!", {
        position: "bottom-left",
        type: "success",
      });
    } catch (error) {
      console.error("Error downloading all documents:", error);
      clearAll();
      notify("There was a problem while downloading some documents", {
        position: "bottom-left",
        type: "error",
      });
    } finally {
      setIsRequestingDownloadAll(false);
      // Reset download states for all documents
      setDownloadStates((prevStates) => prevStates.fill(false));
    }
  };

  const handleDownload = async (
    documentlink: string,
    multiple: boolean,
    index: number = 0
  ) => {
    const isDownloadingThisDoc = downloadStates[index];
    if (isDownloadingThisDoc) {
      return; // Prevent redundant downloads
    }

    setDownloadStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = true;
      return updatedStates;
    });

    if (!multiple) {
      notify("Downloading document, this could take up to 30 seconds", {
        position: "bottom-left",
        type: "info",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }

    try {
      const response = await axios.get(documentlink, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      });

      const docName = getFilenameFromUrl(documentlink);
      clearAll();
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = docName;
      link.click();
    } catch (error) {
      console.error("Error downloading document:", error);
      clearAll();
      setTimeout(() => {
        notify("There was a problem while downloading the document", {
          position: "bottom-left",
          type: "error",
        });
      }, 100);
    } finally {
      setDownloadStates((prevStates) => {
        const updatedStates = [...prevStates];
        updatedStates[index] = false;
        return updatedStates;
      });
    }
  };

  return (
    <Slider isOpen={isOpen} onRequestClose={onRequestClose} width="70%">
      {documentsLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!documentsLoading && (
        <CheckContainer>
          <Box px={4} py={2}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={2}
              mr={5}
            >
              <H2 mb={0}>Uploaded Documents</H2>

              {documents?.data && documents?.data?.length > 0 && (
                <Button
                  onClick={handleDownloadAll}
                  variant="secondary"
                  isDisabled={isRequestingDownloadAll}
                >
                  <Image
                    src={downloadIcon}
                    alt="download pdf"
                    height="auto"
                    width="22px"
                    mr={1}
                  />
                  <Paragraph
                    fontWeight="500"
                    fontSize={1}
                    color={"blue.primary"}
                  >
                    {isRequestingDownloadAll
                      ? "Downloading..."
                      : "Download All"}
                  </Paragraph>
                </Button>
              )}
            </Flex>

            {documents?.data &&
              documents?.data?.length > 0 &&
              documents?.data.map((document, index) => (
                <Box
                  borderBottom="1px solid"
                  borderBottomColor="gray.100"
                  key={index}
                  my={2}
                >
                  <Flex
                    mb={4}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <H3 mb={"0px"}>{document.name}</H3>
                    {document?.document_url && (
                      <Button
                        onClick={() =>
                          handleDownload(document?.document_url, false, index)
                        }
                        variant="text"
                        isDisabled={downloadStates[index]}
                      >
                        <Image
                          src={downloadIcon}
                          alt="download"
                          height="auto"
                          width="22px"
                          mr={1}
                        />
                        <span>
                          {downloadStates[index]
                            ? "Downloading..."
                            : "Download"}
                        </span>
                      </Button>
                    )}
                  </Flex>
                  <Box marginBottom={3}>
                    <Heading>Uploaded</Heading>
                    <StyleParagraph>
                      {DateTime.fromISO(document.created_on).toFormat(
                        "MMMM dd 'at' h:mma"
                      )}
                    </StyleParagraph>
                  </Box>

                  <Box marginBottom={3}>
                    <Heading>Document name:</Heading>
                    <StyleParagraph>
                      {getFilenameFromUrl(document.document_url)}
                    </StyleParagraph>
                  </Box>

                  <Box marginBottom={3}>
                    <Heading>Description:</Heading>
                    <StyleParagraph>{document.description}</StyleParagraph>
                  </Box>
                </Box>
              ))}

            {documents?.data?.length === 0 && (
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                margin={"12% auto"}
                justifyContent={"center"}
              >
                <Image src={noDocuments} alt="No documents" height="auto" />
                <H2>No documents available</H2>
              </Flex>
            )}
          </Box>
        </CheckContainer>
      )}
    </Slider>
  );
};
