import { useState } from "react";
import styled from "styled-components";
import { Flex } from "../Flex";

const StyledSlider = styled.input.attrs({
  type: "range",
})`
  ${({ theme: { colors } }: any) => `
      background: ${colors.gray[30]};
      border-radius: 12px;
      height: 18px;
      cursor: pointer;
      width:100%;

      &:focus {
        outline: none; /* Remove focus outline */
      }
      
      &::-webkit-slider-thumb {        
        width: 20px; /* Thumb width */
        height: 20px; /* Thumb height */
        border-radius: 50%;
        background: ${colors.blue.primary};
        cursor: pointer;
      }
  `}
`;

const RangeLabelContainer = styled.span`
  ${({ theme: { colors, fontFamily, space, fontSizes } }: any) => `
      color: ${colors.gray[60]};
      font-family: ${fontFamily};
      margin-right: ${space[1]};
      margin-left: ${space[1]};
      font-size: ${fontSizes[1]};
  `}
`;
const ThumbValue = styled.span`
  ${({ theme: { colors, fontFamily, fontSizes } }: any) => `
      position: absolute;
      font-family: ${fontFamily};
      transform: translate(-50%, 0);
      color: ${colors.gray[60]};
      font-size: ${fontSizes[1]};
  `}
`;

const RangeSliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const RangeSlider = ({
  min = 0,
  max = 100,
  onChange,
  toleranceValue = min,
}) => {
  const [value, setValue] = useState(toleranceValue); // Initial value

  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  const calculateThumbPosition = () => {
    const percent = ((value - min) / (max - min)) * 100;
    return `calc(${percent}% + (${8 - percent * 0.15}px))`; // Adjust for thumb width
  };

  return (
    <Flex alignItems={"center"}>
      <RangeLabelContainer>{min}</RangeLabelContainer>
      <RangeSliderContainer>
        <StyledSlider
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={handleChange}
        />
        <ThumbValue style={{ left: calculateThumbPosition() }}>
          {value}
        </ThumbValue>
      </RangeSliderContainer>
      <RangeLabelContainer>{max}</RangeLabelContainer>
    </Flex>
  );
};

export default RangeSlider;
