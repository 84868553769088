import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { Slider } from "../../components/Slider";
import { CheckStatusEnum } from "../../context/MandateStatusContext";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { IndividualChecksMap } from "./helper";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { CheckResults } from "./CheckResults";
import { DateTime } from "luxon";
import { CheckFields } from "./CheckFields";
import { ApprovalComment } from "./approvalComment";

export const PersonalDetailsCheck = (props) => {
  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.personal_details_match_check.title}
              </CheckTitle>

              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  id={id}
                  title={IndividualChecksMap.personal_details_match_check.title}
                ></ManualAcceptReject>
              )}
            </Flex>
            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}

            {instance && instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              ></ApprovalComment>
            )}

            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            ></CheckFields>

            {instance && (
              <Section>
                <Flex gap="24">
                  <Box flex={1}>
                    <H3>Invite Details</H3>
                    <Box mb={2}>
                      <CheckHeading>First name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.invite_details?.first_name
                          ? instance?.invite_details?.first_name
                          : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Middle name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.invite_details?.middle_name
                          ? instance?.invite_details?.middle_name
                          : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Last name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.invite_details?.last_name
                          ? instance?.invite_details?.last_name
                          : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Date of birth:</CheckHeading>
                      <CheckParagraph>
                        {instance?.invite_details?.date_of_birth
                          ? DateTime.fromISO(
                              instance?.invite_details?.date_of_birth
                            ).toFormat("dd/MM/yyyy")
                          : "-"}
                      </CheckParagraph>
                    </Box>
                  </Box>
                  <Box flex={1}>
                    <H3>Personal Details</H3>
                    <Box mb={2}>
                      <CheckHeading>First name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.first_name ? instance?.first_name : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Middle name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.middle_name ? instance?.middle_name : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Last name:</CheckHeading>
                      <CheckParagraph>
                        {instance?.last_name ? instance?.last_name : "-"}
                      </CheckParagraph>
                    </Box>
                    <Box mb={2}>
                      <CheckHeading>Date of birth:</CheckHeading>
                      <CheckParagraph>
                        {instance?.date_of_birth
                          ? DateTime.fromISO(instance?.date_of_birth).toFormat(
                              "dd/MM/yyyy"
                            )
                          : "-"}
                      </CheckParagraph>
                    </Box>
                  </Box>
                </Flex>
              </Section>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
