import ReactSelect from "react-select";
import { useTheme } from "styled-components";
import { Box, BoxProps } from "../Box";
import { Label } from "../Label";
import { Paragraph } from "../Paragraph";

export type Option = {
  label: string;
  value: string | number;
};

interface SelectProps extends BoxProps {
  options?: Option[];
  onChange: (newValue: Option) => void;
  value: Option | null | undefined | "" | number;
  placeholder?: string;
  color?: string;
  label?: string;
  size?: "regular" | "large";
  name?: string;
  initialValue?: Option;
  isDisabled?: boolean;
  isInModal?: boolean;
  isRequired?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  getOptionLabel?: any;
  menuIsOpen?: boolean; 
}

const Select = (props: SelectProps) => {
  const {
    options,
    onChange,
    value,
    placeholder,
    errorMessage,
    hasError,
    label,
    name,
    initialValue,
    isDisabled,
    size = "large",
    isInModal,
    isRequired,
    getOptionLabel,
    menuIsOpen,
    ...styleProps
  } = props;

  const { colors, radii, space } = useTheme() as any;

  return (
    <Box {...styleProps}>
      {label && (
        <Label>
          {label}
          {isRequired ? " *" : ""}
        </Label>
      )}
      <ReactSelect
        options={options}
        isDisabled={isDisabled}
        onChange={onChange as any}
        value={initialValue ?? value}
        placeholder={placeholder}
        getOptionLabel={getOptionLabel}
        menuIsOpen={menuIsOpen}
        name={name}
        menuPortalTarget={isInModal ? document.body : null}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({
            ...base,
            border: ` 2px solid ${
              hasError ? colors.red.primary : colors.gray[30]
            };`,
            borderRadius: radii[0],
            height: 60,
            paddingLeft: space[1],
            fontFamily: "Inter",
            boxShadow: "none",
            maxHeight: size === "regular" && 40,
            "::placeholder": {
              color: colors.gray[50],
            },
            ":hover": {
              border: `2px solid ${colors.gray[40]}`,
            },
            ...space,
          }),
          option: (base, { isSelected, isFocused }) => ({
            ...base,
            fontFamily: "Inter",
            height: size === "regular" ? 40 : 60,
            display: "flex",
            alignItems: "center",
            fontWeight: isSelected ? "bold" : "regular",
            backgroundColor:
              isFocused || isSelected ? colors.background : colors.white,
            color: "#aaaaaaa",
            ":hover": {
              backgroundColor: colors.background,
            },
            ...space,
          }),
        }}
      />

      {errorMessage && (
        <Paragraph color="red.primary" fontSize="1" mt="1" textAlign="right">
          {errorMessage}
        </Paragraph>
      )}
    </Box>
  );
};

export default Select;
