import { Flex } from "../../components/Flex";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { Slider } from "../../components/Slider";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Box } from "../../components/Box";
import { TableHead } from "../../components/Table/TableHead";
import { TableBody } from "../../components/Table/TableBody";
import { TableRow } from "../../components/Table/TableRow";
import { TableCell } from "../../components/Table/TableCell";
import { Image } from "../../components/Image";
import downloadIcon from "../../assets/download-blue.svg";
import {
  CheckContainer,
  CheckHeading,
  CheckParagraph,
  CheckTable,
  CheckTableCell,
  CheckTableHeaderRow,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import {
  IndividualCheckStatus,
  CheckStatusEnum,
  RiskLevelEnum,
  RiskLevelColors,
  RiskLevelIcons,
} from "../../context/MandateStatusContext";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { IndividualChecksMap } from "./helper";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { capitalise } from "../../utils/string";
import { GrayCard } from "../../components/Card";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { useState } from "react";
import axios from "axios";
import { notify, clearAll } from "../../utils/notify";
import { useAuth } from "../../context/AuthContext";
import { ApprovalComment } from "./approvalComment";
import theme from "../../theme";

export const SourceOfFundsCheck = (props) => {
  const { authHeader } = useAuth();

  const { isOpen, onRequestClose, id } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const [isRequestingDownloadAll, setIsRequestingDownloadAll] = useState(false);

  const instance = individualCheckData?.data?.instance;

  const totalRiskLevelCount = instance?.risk_levels?.details.reduce(
    (accumulator, risk) => accumulator + risk.amount,
    0
  );

  const getFilenameFromUrl = (url) => {
    const urlObj = new URL(url);
    const segments = urlObj.pathname.split("/");
    return segments.pop();
  };

  // download state for each document
  const [downloadStates, setDownloadStates] = useState(
    instance?.source_detail_and_evidences?.details.map(() => false) || []
  );

  const handleDownload = async (
    documentlink: string,
    multiple: boolean,
    index: number = 0
  ) => {
    const isDownloadingThisDoc = downloadStates[index];
    if (isDownloadingThisDoc) {
      return; // Prevent redundant downloads
    }

    setDownloadStates((prevStates) => {
      const updatedStates = [...prevStates];
      updatedStates[index] = true;
      return updatedStates;
    });

    if (!multiple) {
      notify("Downloading document, this could take up to 30 seconds", {
        position: "bottom-left",
        type: "info",
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      });
    }

    try {
      const response = await axios.get(documentlink, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      });

      const docName = getFilenameFromUrl(documentlink);
      clearAll();
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      if (docName.includes(".")) {
        link.download = docName;
      } else {
        link.download = docName + `.jpeg`;
      }
      link.click();
    } catch (error) {
      console.error("Error downloading document:", error);
      clearAll();
      setTimeout(() => {
        notify("There was a problem while downloading the document", {
          position: "bottom-left",
          type: "error",
        });
      }, 100);
    } finally {
      setDownloadStates((prevStates) => {
        const updatedStates = [...prevStates];
        updatedStates[index] = false;
        return updatedStates;
      });
    }
  };

  const handleDownloadAll = async () => {
    if (isRequestingDownloadAll) {
      return;
    }

    setIsRequestingDownloadAll(true);

    notify("Downloading all, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: false,
    });

    // Update download states for all documents
    setDownloadStates((prevStates) => prevStates.fill(true));
    const downloadPromises = instance?.source_detail_and_evidences?.details.map(
      (source, index) => {
        if (source.document) {
          return handleDownload(source.document, true, index);
        }
      }
    );

    try {
      await Promise.all(downloadPromises);

      setIsRequestingDownloadAll(false); // Update state after all downloads are complete
      clearAll();
      notify("All documents downloaded successfully!", {
        position: "bottom-left",
        type: "success",
      });
    } catch (error) {
      console.error("Error downloading all documents:", error);
      clearAll();
      notify("There was a problem while downloading some documents", {
        position: "bottom-left",
        type: "error",
      });
    } finally {
      setIsRequestingDownloadAll(false);
      // Reset download states for all documents
      setDownloadStates((prevStates) => prevStates.fill(false));
    }
  };

  return (
    <>
      {isLoadingIndividualCheck && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}
      {!isLoadingIndividualCheck && (
        <Slider
          isOpen={isOpen}
          onRequestClose={() => onRequestClose(false)}
          width="70%"
        >
          <CheckContainer>
            <Flex justifyContent="space-between">
              <CheckTitle>
                {IndividualChecksMap.source_of_funds_check.title}
              </CheckTitle>

              {individualCheckData?.data?.status?.status ===
                CheckStatusEnum.Review && (
                <ManualAcceptReject
                  title={IndividualChecksMap.source_of_funds_check.title}
                  id={id}
                ></ManualAcceptReject>
              )}
            </Flex>
            {individualCheckData?.data?.status?.status && (
              <CheckResults
                status={individualCheckData?.data?.status?.status}
              ></CheckResults>
            )}
            {instance && instance?.approval_comment && (
              <ApprovalComment
                data={instance?.approval_comment}
                status={individualCheckData?.data?.status?.status}
              ></ApprovalComment>
            )}

            <CheckFields
              individual_checkfield_result={
                individualCheckData?.data?.individual_checkfield_result
              }
            ></CheckFields>

            {instance?.transaction_amount && (
              <Section pb={4}>
                <Flex>
                  <Image
                    src={
                      instance?.transaction_amount?.status ===
                      IndividualCheckStatus.Passed
                        ? success
                        : inReview
                    }
                    alt="status icon"
                    height="20px"
                    width="auto"
                    paddingTop="5px"
                    mr={1}
                  />
                  <H3>Transaction amount</H3>
                </Flex>
                <Flex gap="90">
                  <Box>
                    <CheckHeading>Transaction amount</CheckHeading>
                    <CheckParagraph>
                      £
                      {(instance?.transaction_amount?.transaction_amount).toLocaleString()}
                    </CheckParagraph>
                  </Box>
                  <Box>
                    <CheckHeading>Source of funds total</CheckHeading>
                    <CheckParagraph>
                      £
                      {(instance?.transaction_amount?.total_source_of_funds).toLocaleString()}
                    </CheckParagraph>
                  </Box>
                </Flex>
              </Section>
            )}

            {instance?.risk_levels && (
              <>
                <Section>
                  <Flex>
                    <Image
                      src={
                        instance?.risk_levels?.status ===
                        IndividualCheckStatus.Passed
                          ? success
                          : inReview
                      }
                      alt="status icon"
                      height="20px"
                      width="auto"
                      paddingTop="5px"
                      mr={1}
                    />
                    <H3>Source of fund types</H3>
                  </Flex>
                  <CheckTable>
                    <TableHead></TableHead>
                    <TableBody>
                      <CheckTableHeaderRow>
                        <CheckTableCell>Types</CheckTableCell>
                        <CheckTableCell>Risk</CheckTableCell>
                        <CheckTableCell>Amount</CheckTableCell>
                      </CheckTableHeaderRow>
                      {instance?.risk_levels?.details.map(
                        ({ types, risk_level, amount }, i) => (
                          <TableRow key={i}>
                            <TableCell
                              style={{ textTransform: "uppercase" }}
                              fontWeight="500"
                              color="gray.80"
                              borderBottom="none"
                            >
                              <Flex gap="8">
                                {/* <Image
                              src={
                                status === IndividualCheckStatus.Passed
                                  ? success
                                  : inReview
                              }
                              alt="status icon"
                              height="20px"
                              width="auto"
                              mr={1}
                            /> */}
                                {types}
                              </Flex>
                            </TableCell>
                            <TableCell
                              width="20%"
                              color="gray.60"
                              borderBottom="none"
                            >
                              <Flex gap="12">
                                <Box
                                  width="70px"
                                  color={RiskLevelColors[risk_level]}
                                >
                                  {" "}
                                  {capitalise(RiskLevelEnum[risk_level])}
                                </Box>
                                <Image
                                  src={RiskLevelIcons[risk_level]}
                                  alt="status icon"
                                  height="20px"
                                  width="20px"
                                />
                              </Flex>
                            </TableCell>
                            <TableCell color="gray.60" borderBottom="none">
                              £{amount.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                      <TableRow>
                        <TableCell fontWeight="600">Total</TableCell>
                        <TableCell></TableCell>
                        <TableCell fontWeight="600">
                          £{totalRiskLevelCount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </CheckTable>
                </Section>
              </>
            )}

            {instance?.source_detail_and_evidences && (
              <>
                <Section>
                  <Flex justifyContent={"space-between"} mb={1}>
                    <Flex>
                      <Image
                        src={
                          instance?.source_detail_and_evidences?.status ===
                          IndividualCheckStatus.Passed
                            ? success
                            : inReview
                        }
                        alt="status icon"
                        height="20px"
                        width="auto"
                        paddingTop="5px"
                        mr={1}
                      />
                      <H3>Source details and evidence</H3>
                    </Flex>
                    <Button
                      onClick={handleDownloadAll}
                      variant="text"
                      isDisabled={isRequestingDownloadAll}
                    >
                      <Image
                        src={downloadIcon}
                        alt="download pdf"
                        height="auto"
                        width="22px"
                        mr={1}
                      />
                      <Paragraph
                        fontWeight="500"
                        fontSize={theme.fontSizes[1]}
                        color={theme.colors.blue.primary}
                      >
                        {isRequestingDownloadAll
                          ? "Downloading..."
                          : "Download all"}
                      </Paragraph>
                    </Button>
                  </Flex>

                  {instance?.source_detail_and_evidences?.details?.map(
                    (source, index) => (
                      <GrayCard key={index} mx="0px">
                        <Flex
                          mb={3}
                          justifyContent={"space-between"}
                          borderBottom="1px solid"
                          borderBottomColor="gray.30"
                        >
                          <H3 py={1}>{source?.source_name}</H3>

                          {source?.document && (
                            <Button
                              onClick={() =>
                                handleDownload(source?.document, false, index)
                              }
                              variant="text"
                              isDisabled={downloadStates[index]}
                            >
                              <Image
                                src={downloadIcon}
                                alt="download"
                                height="auto"
                                width="22px"
                                mr={1}
                              />
                            </Button>
                          )}
                        </Flex>

                        <Flex justifyContent="space-between">
                          <Box flex={1}>
                            <CheckHeading color="gray.60" fontWeight="500">
                              Amount
                            </CheckHeading>
                            <Paragraph
                              mt={1}
                              mb={2}
                              color="gray.80"
                              fontWeight="500"
                            >
                              {source?.amount
                                ? "£" + source?.amount.toLocaleString()
                                : "-"}
                            </Paragraph>
                          </Box>

                          <Box flex={1}>
                            <CheckHeading color="gray.60" fontWeight="500">
                              Risk Level
                            </CheckHeading>

                            {source?.risk_level ? (
                              <Flex gap="12">
                                <Box
                                  width="70px"
                                  fontWeight="500"
                                  fontFamily={theme.fontFamily}
                                  color={RiskLevelColors[source?.risk_level]}
                                >
                                  {" "}
                                  {capitalise(
                                    RiskLevelEnum[source?.risk_level]
                                  )}
                                </Box>
                                <Image
                                  src={RiskLevelIcons[source?.risk_level]}
                                  alt="status icon"
                                  height="20px"
                                  width="20px"
                                />
                              </Flex>
                            ) : (
                              "-"
                            )}
                          </Box>
                        </Flex>

                        <Box>
                          <CheckHeading color="gray.60" fontWeight="500">
                            Description
                          </CheckHeading>
                          <Paragraph
                            mt={1}
                            mb={2}
                            color="gray.80"
                            fontWeight="500"
                          >
                            {source?.description ? source?.description : "-"}
                          </Paragraph>
                        </Box>

                        <Box>
                          <CheckHeading color="gray.60" fontWeight="500">
                            Document name
                          </CheckHeading>
                          <Paragraph
                            mt={1}
                            mb={2}
                            color="gray.80"
                            fontWeight="500"
                          >
                            {source?.document
                              ? getFilenameFromUrl(source?.document)
                              : "No document uploaded"}
                          </Paragraph>
                        </Box>
                      </GrayCard>
                    )
                  )}
                </Section>
              </>
            )}
          </CheckContainer>
        </Slider>
      )}
    </>
  );
};
