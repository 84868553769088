import { Input, InputProps } from "../Input";
import { H2 } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Form, Formik } from "formik";
import { Flex } from "../Flex";
import { Button } from "../Button";
import { useCreateIndividual } from "../../data/useCreateIndividual";
import { queryClient } from "../../queryClient";
import { displayError, showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import Select, { Option } from "../Select";
import { Modal } from "../Modal";

import { addIndividualValidation } from "../../utils/validationSchema";
import { DatePicker } from "../DatePicker";
import { DateTime } from "luxon";
import { useCreateIndividualsInvites } from "../../data/useCreateIndividualsInvites";
import { MIN_AGE_APPLICATION } from "../../utils/constants";
import { useCommonTitles } from "../../data/useCommonTitles";
import { useState } from "react";
import { Loader } from "../Loader";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "2",
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

export const AddIndividualModal = ({
  formTitle,
  formSubtitle,
  submitButtonText,
  onRequestClose,
  mandateId,
  isOpen,
}) => {
  const [doubleClick, setDoubleClick] = useState(false);

  const { mutate: createIndividual } = useCreateIndividual();

  const { mutate: createIndividualsInvites, isLoading } =
    useCreateIndividualsInvites();

  const { data } = useCommonTitles();

  const titleOptions = data?.data.map(({ name }) => ({
    label: name,
    value: name,
  })) as Option[];

  const handleSendInvites = ({ mandateId, individualId }) => {
    createIndividualsInvites(
      {
        individual_ids: [individualId],
        id: mandateId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["mandate", mandateId]);
          notify("Individual added and invite sent", {
            type: "success",
          });
          setDoubleClick(false);
          onRequestClose();
        },
        onError: (err: any) => {
          setDoubleClick(false);
          showError(err, "A problem occurred while creating individual.");
          // displayError(
          //   collapseErrors(extractErrors(err)) ||
          //     "A problem occurred while creating individual."
          // );
          // logError(err);
        },
      }
    );
  };

  const handleCreateIndividual = (form: any) => {
    setDoubleClick(true);
    const dob = form.dateOfBirth
      ? DateTime.fromJSDate(form.dateOfBirth as Date).toFormat("yyyy-MM-dd")
      : null;

    if (mandateId) {
      createIndividual(
        {
          gender: form.gender,
          title: form.title,
          first_name: form.firstName,
          date_of_birth: dob,
          last_name: form.lastName,
          middle_name: form.middleName,
          mandate: mandateId,
          email: form.email,
        },
        {
          onSuccess: ({ data }) => {
            handleSendInvites({ mandateId, individualId: data?.id });
          },
          onError: (err: any) => {
            setDoubleClick(false);
            // displayError(
            //   collapseErrors(extractErrors(err)) ||
            //     "A problem occurred while adding the individual."
            // );
            showError(err, "A problem occurred while adding the individual.");
            // logError(err);
          },
        }
      );
    } else {
      setDoubleClick(false);
      displayError("A problem occurred while creating individual.");
    }
  };

  const inputProps: Partial<InputProps> = {
    minWidth: 200,
    flex: 1,
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      <>
        <Formik
          initialValues={{
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            dateOfBirth: "",
            email: "",
            gender: "",
          }}
          validationSchema={addIndividualValidation}
          onSubmit={(values) => {
            handleCreateIndividual(values);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: "700px" }}>
              <H2>{formTitle}</H2>
              <Paragraph>{formSubtitle}</Paragraph>
              <Flex {...rowProps}>
                <div style={{ width: "49%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("title", option.value)
                    }
                    value={titleOptions?.find(
                      ({ value }) => value === props.values.title
                    )}
                    options={titleOptions}
                    placeholder="Select title"
                    mt="2"
                    label="Title"
                    name="title"
                    isRequired
                    hasError={Boolean(props.errors.title)}
                    errorMessage={props.errors.title}
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("gender", option.value)
                    }
                    value={genderOptions?.find(
                      ({ value }) => value === props.values.gender
                    )}
                    options={genderOptions}
                    placeholder="Select gender"
                    mt="2"
                    label="Select gender"
                    name="gender"
                    isRequired
                    hasError={Boolean(props.errors.gender)}
                    errorMessage={props.errors.gender}
                  />
                </div>
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  placeholder="Enter first name"
                  mt="2"
                  label="First name"
                  name="firstName"
                  hasError={Boolean(props.errors.firstName)}
                  errorMessage={props.errors.firstName}
                  isRequired
                />
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.middleName}
                  placeholder="Enter middle(s) name"
                  mt="2"
                  label="Middle name(s)"
                  name="middleName"
                  hasError={Boolean(props.errors.middleName)}
                  errorMessage={props.errors.middleName}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  placeholder="Enter last name"
                  mt="2"
                  label="Last name"
                  name="lastName"
                  hasError={Boolean(props.errors.lastName)}
                  errorMessage={props.errors.lastName}
                  isRequired
                />
                <DatePicker
                  value={props.values.dateOfBirth as any}
                  onChange={(date: any) => {
                    props.setFieldValue("dateOfBirth", date);
                  }}
                  mt="2"
                  label="Date of birth"
                  name="dateOfBirth"
                  maxDate={DateTime.now()
                    .minus({ years: MIN_AGE_APPLICATION })
                    .toJSDate()}
                  {...inputProps}
                  hasError={Boolean(props.errors.dateOfBirth)}
                  errorMessage={props.errors.dateOfBirth as string}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.email}
                  placeholder="Enter email address"
                  mt="2"
                  label="Email address"
                  name="email"
                  hasError={Boolean(props.errors.email)}
                  errorMessage={props.errors.email}
                  isRequired
                />
              </Flex>
              <Flex justifyContent="flex-end">
                <Button
                  size="large"
                  mt="2"
                  mb="0"
                  type="submit"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    isLoading ||
                    doubleClick
                  }
                >
                  {submitButtonText}

                  {(doubleClick || isLoading) && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      minHeight="25vh"
                      margin="-35px 15px 0 15px;"
                    >
                      <Loader />
                    </Flex>
                  )}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};
